import mainTask from '../../service/services/mainTask'
import {handleResultCode} from "../../service/service"

const HANDLE_ERROR = 'HANDLE_ERROR',
    START_LOADING_FLOWDEFINE = 'START_LOADING_FLOWDEFINE',
    FINISH_LOADING_FLOWDEFINE = 'FINISH_LOADING_FLOWDEFINE'

const initState = {
    flowDefineIsFetching: false,
    flowDefineList: []
}

function startGettingFlowDefine() {
    return {
        type: START_LOADING_FLOWDEFINE
    }
}

function finishGettingFlowDefine(res) {
    return {
        type: FINISH_LOADING_FLOWDEFINE,
        flowDefineList: res
    }
}

function handleError() {
    return {
        type: HANDLE_ERROR
    }
}

export function getFlowDefine() {
    return dispatch => {
        dispatch(startGettingFlowDefine())
        mainTask.queryFlow()
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishGettingFlowDefine(res.data.flowDefineDTOList))
                } else {
                    dispatch(handleError())
                }
            })
    }
}

export function flowDefine(state = initState, action) {
    switch (action.type) {
        case START_LOADING_FLOWDEFINE:
            return {
                ...state,
                flowDefineIsFetching: true
            }
        case FINISH_LOADING_FLOWDEFINE:
            return {
                ...state,
                flowDefineIsFetching: false,
                flowDefineList: action.flowDefineList
            }
        case HANDLE_ERROR:
            return {
                ...state,
                flowDefineIsFetching: false
            }
        default:
            return state
    }
}