import axios from '../service'

const url = '/xbizapi/226/statistics'

var statistics = {}

statistics.queryAudioStatistics = function (operationType, data) {
    return axios(url, {
        data: {
            operationType,
            ...data
        }
    })
}

statistics.exportAudioStatistics = function (operationType, data) {
    return axios(url, {
        data: {
            operationType,
            ...data
        }
    })
}

export default statistics