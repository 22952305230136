import axios from '../service'

const classesUrl = '/xbizapi/226/classes'

const schedulerUrl = '/xbizapi/226/scheduler'

var classesListService = {}

var schedulerListService = {}



//班次api

const classesApis = [{
    name: "query",
    operationType: "QUERY_CLASSES"
}]
classesApis.map((api) => {
    classesListService[api.name] = function (data) {
        return axios(classesUrl, {
            data: Object.assign({}, {operationType: api.operationType}, data)
        })
    }
})



//值班人员api
const schedulerApis = [
    {
        //获取值班人员列表
        name: "getSchedulerList",
        operationType: "QUERY_USER"
    }, {
        //获取班次列表
        name: "getScheduleList",
        operationType: "QUERY_SCHEDULER_DATE_LIST"
    }, {
        //添加班次
        name: "handleSubmit",
        operationType: "SAVE_OR_UPDATE_SCHEDULER"
    },
    {
        //删除班次
        name: "deleteSchedule",
        operationType: "DEL_SCHEDULER"
    },
]
schedulerApis.map((api) => {
    schedulerListService[api.name] = function (data) {
        return axios(schedulerUrl, {
            data: Object.assign({}, {operationType: api.operationType}, data)
        })
    }
})

export {classesListService, schedulerListService}