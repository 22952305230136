import { handleResultCode } from '../../service/service'
import roleManageService from '../../service/services/roleManage'
import {getTranslateArray} from "../../utils"
const START_GET_FUNC_LIST = 'START_GET_FUNC_LIST'
const DID_GET_FUNC_LIST = 'DID_GET_FUNC_LIST'
const FAIL_GET_FUNC_LIST = 'FAIL_GET_FUNC_LIST'

const START_GET_ROLE_LIST = 'START_GET_ROLE_LIST'
const DID_GET_ROLE_LIST = 'DID_GET_ROLE_LIST'
const FAIL_GET_ROLE_LIST = 'FAIL_GET_ROLE_LIST'

const START_GET_ROLE_INFO = 'START_GET_ROLE_INFO'
const DID_GET_ROLE_INFO = 'DID_GET_ROLE_INFO'
const FAIL_GET_ROLE_INFO = 'FAIL_GET_ROLE_INFO'

const START_SUBMIT_ROLE_INFO = 'START_SUBMIT_ROLE_INFO'
const DID_SUBMIT_ROLE_INFO = 'DID_SUBMIT_ROLE_INFO'
const FAIL_SUBMIT_ROLE_INFO = 'FAIL_SUBMIT_ROLE_INFO'

const INIT_ROLE_INFO = 'INIT_ROLE_INFO'

const initState = {
    isFetchRoleList: false,
    isFetchSubmitRoleInfo: false,
    totalItemCount: 0,
    rows: [],
    roleInfo: {},
    roleNameList: [],
}


// 获取Func 数据
export function getFuncList(params, successCb, failCb) {
    return (dispatch) => {
        dispatch(startGetFuncList())
        roleManageService.getFuncList(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    let {functionDTOS} = res.data
                    let functionInfos = (functionDTOS.length && functionDTOS) || []

                    let roleInfo = {
                        functions: [],
                        roleDesc: '',
                        roleName: '',
                        id: null
                    }
                    roleInfo.functions = getTranslateArray(functionInfos, false)
                    roleInfo.roleDesc = ''
                    roleInfo.roleName = ''
                    roleInfo.id = ''
                    dispatch(didGetFuncList(roleInfo))
                } else {
                    dispatch(failGetFuncList())
                    failCb()
                }
            })
            .catch(() => {
                dispatch(failGetFuncList())
                failCb()
            })
    }
}

function startGetFuncList() {
    return {
        type: START_GET_FUNC_LIST
    }
}

function didGetFuncList(roleInfo) {
    return {
        type: DID_GET_FUNC_LIST,
        roleInfo,
    }
}

function failGetFuncList() {
    return {
        type: FAIL_GET_FUNC_LIST
    }
}

// 获取table 数据
export function getRoleList(params, successCb, failCb) {
    return (dispatch) => {
        dispatch(startGetRoleList())
        roleManageService.getRoleList(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    let {totalItemCount, roleDTOList} = res.data
                    dispatch(didGetRoleList(totalItemCount, roleDTOList))
                } else {
                    dispatch(failGetRoleLoList())
                    failCb()
                }
            })
            .catch(() => {
                dispatch(failGetRoleLoList())
                failCb()
            })
    }
}

function startGetRoleList() {
    return {
        type: START_GET_ROLE_LIST
    }
}

function didGetRoleList(totalItemCount, rows) {
    return {
        type: DID_GET_ROLE_LIST,
        totalItemCount,
        rows,
    }
}

function failGetRoleLoList() {
    return {
        type: FAIL_GET_ROLE_LIST
    }
}


// 获取角色信息
export function getRoleInfo(params, successCb, failCb) {
    return (dispatch) => {
        dispatch(startGetRoleInfo())
        roleManageService.getRoleInfo(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {

                if (res.success) {
                    let {roleDTOList} = res.data
                    let roleInfo = {
                        functions: [],
                        roleDesc: '',
                        roleName: '',
                        id: null
                    }
                    if (roleDTOList && roleDTOList.length) {
                        roleInfo.functions = getTranslateArray(roleDTOList[0].functionInfos || [], false)
                        roleInfo.roleDesc = roleDTOList[0].description
                        roleInfo.roleName = roleDTOList[0].roleName
                        roleInfo.id = roleDTOList[0].id
                    }
                    dispatch(didGetRoleInfo(roleInfo))
                    successCb()
                } else {
                    dispatch(failGetRoleLoInfo())
                    failCb()
                }
            })
            .catch(() => {
                dispatch(failGetRoleLoInfo())
                failCb()
            })
    }
}

function startGetRoleInfo() {
    return {
        type: START_GET_ROLE_INFO
    }
}

function didGetRoleInfo(roleInfo) {
    return {
        type: DID_GET_ROLE_INFO,
        roleInfo
    }
}

function failGetRoleLoInfo() {
    return {
        type: FAIL_GET_ROLE_INFO
    }
}


// submitRoleInfo 编辑角色信息
export function submitRoleInfo(params, successCb, failCb) {
    return (dispatch) => {
        dispatch(startSubmitRoleInfo())
        roleManageService.submitRoleInfo(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(didSubmitRoleInfo())
                    successCb()
                } else {
                    dispatch(failSubmitRoleLoInfo())
                    failCb()
                }
            })
            .catch(() => {
                dispatch(failSubmitRoleLoInfo())
                failCb()
            })
    }
}

function startSubmitRoleInfo() {
    return {
        type: START_SUBMIT_ROLE_INFO
    }
}

function didSubmitRoleInfo() {
    return {
        type: DID_SUBMIT_ROLE_INFO,
    }
}

function failSubmitRoleLoInfo() {
    return {
        type: FAIL_SUBMIT_ROLE_INFO
    }
}


export function roleManage(state = initState, action) {
    switch (action.type) {
        case START_GET_FUNC_LIST:
            return {
                ...state,
                isFetchRoleInfo: true
            }
        case DID_GET_FUNC_LIST:
            return {
                ...state,
                roleInfo: action.roleInfo,
                isFetchRoleInfo: false
            }
        case FAIL_GET_FUNC_LIST:
            return {
                ...state,
                isFetchRoleInfo: true
            }
        case START_GET_ROLE_LIST:
            return {
                ...state,
                isFetchRoleList: true
            }
        case DID_GET_ROLE_LIST:
            // eslint-disable-next-line no-case-declarations
            const result = {
                ...state,
                isFetchRoleList: false,
                rows: action.rows,
                totalItemCount: action.totalItemCount,
            }
            if (!state.roleNameList.length) {
                result.roleNameList = action.rows.map(item => item.roleName)
            }
            return result
        case FAIL_GET_ROLE_LIST:
            return {
                ...state,
                isFetchRoleList: false,
                rows: [],
                roleNameList: []
            }
        case START_GET_ROLE_INFO:
            return {
                ...state,
                isFetchRoleInfo: true
            }
        case DID_GET_ROLE_INFO:
            return {
                ...state,
                isFetchRoleInfo: false,
                roleInfo: action.roleInfo,
            }
        case FAIL_GET_ROLE_INFO:
            return {
                ...state,
                isFetchRoleInfo: false,
                roleInfo: {}
            }
        case START_SUBMIT_ROLE_INFO:
            return {
                ...state,
                isFetchSubmitRoleInfo: true
            }
        case DID_SUBMIT_ROLE_INFO:
            return {
                ...state,
                isFetchSubmitRoleInfo: false,
            }
        case FAIL_SUBMIT_ROLE_INFO:
            return {
                ...state,
                isFetchSubmitRoleInfo: false
            }
        case INIT_ROLE_INFO:
            return {
                ...state,
                roleInfo: {}
            }
        default:
            return state
    }
}
