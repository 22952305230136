import axios from '../service'

const url = '/xbizapi/226/classes'

var scheduleListService = {}

const apis = [{
    name: "query",
    operationType: "QUERY_CLASSES"
},{
    name: "delete",
    operationType: "DEL_SCHEDULER"
},{
    name: 'add',
    operationType: 'ADD_CLASSES'
}, {
    name: 'update',
    operationType: 'UPDATE_CLASSES'
}]

apis.map((api) => {
    scheduleListService[api.name] = function (data) {
        return axios(url, {
            data: Object.assign({}, {operationType: api.operationType}, data)
        })
    }
})

export default scheduleListService