function getScrollWidth(colums) {
    // 根据table的列数显示不同的最小宽度
    // 根据table的列数显示不同的最小宽度
    let scrollWidth = 1050
    if (colums.length < 15) {
        scrollWidth = scrollWidth + (colums.length - 7 > 0 ? colums.length - 7 : 0) * 130
    } else if (colums.length >= 15 && colums.length < 19) {
        scrollWidth = scrollWidth + (colums.length - 7 > 0 ? colums.length - 7 : 0) * 160
    } else if (colums.length >= 19) {
        scrollWidth = scrollWidth + (colums.length - 10 > 0 ? colums.length - 10 : 0) * 120
    }
    return scrollWidth
}

export default getScrollWidth
