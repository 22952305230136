// import favorite from '../../service/services/favorite'
import subtask from '../../service/services/subtask'
import taskItem from '../../service/services/taskItem'
import {handleResultCode} from "../../service/service"

const HANDLE_ERROR = 'HANDLE_ERROR',
    START_LOADING_SUBTASK_DETAIL = 'START_LOADING_SUBTASK_DETAIL',
    FINISH_LOADING_SUBTASK_DETAIL = 'FINISH_LOADING_SUBTASK_DETAIL',
    START_LOADING_TASK_ITEM = 'START_LOADING_TASK_ITEM',
    FINISH_LOADING_TASK_ITEM = 'FINISH_LOADING_TASK_ITEM',
    UPDATE_SUBTASK_DETAIL_MARKED_COUNT = 'UPDATE_SUBTASK_DETAIL_MARKED_COUNT',
    CLEAR_TASK = 'CLEAR_TASK',
    UPDATE_SUBTASK_DETAIL_PROCESS_AUDIO_DURATION = 'UPDATE_SUBTASK_DETAIL_PROCESS_AUDIO_DURATION',
    UPDATE_SUBTASK_DETAIL_TOTAL_NUMBER_OF_TASKS = 'UPDATE_SUBTASK_DETAIL_TOTAL_NUMBER_OF_TASKS'

const initState = {
    subtaskDetailIsFetching: true,
    taskItemIsFetching: false,
    historyListIsFetching: false,
    favoriteListIsFetching: false,
    subtaskDetail: null,
    taskItem: null,
    history: null,
    favorite: null
}

function handleError() {
    return {
        type: HANDLE_ERROR
    }
}

export function clearTask() {
    return {
        type: CLEAR_TASK
    }
}

function startGettingTaskItem() {
    return {
        type: START_LOADING_TASK_ITEM
    }
}

function finishGettingTaskItem(res) {
    return {
        type: FINISH_LOADING_TASK_ITEM,
        taskItem: res
    }
}

export function getTaskItem(params) {
    return dispatch => {
        dispatch(startGettingTaskItem())
        taskItem.get(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishGettingTaskItem(res.data))
                } else {
                    dispatch(handleError())
                }
            })
    }
}

export function updateSubtaskDetailMarkedCount(markedCount) {
    return {
        type: UPDATE_SUBTASK_DETAIL_MARKED_COUNT,
        markedCount: markedCount
    }
}

export function updateSubtaskDetailProcessAudioDuration(processAudioDuration) {
    return {
        type: UPDATE_SUBTASK_DETAIL_PROCESS_AUDIO_DURATION,
        processAudioDuration: processAudioDuration
    }
}

export function updateSubtaskDetailsTotalNumberOfTasks(taskItemCount) {
    return {
        type: UPDATE_SUBTASK_DETAIL_TOTAL_NUMBER_OF_TASKS,
        taskItemCount: taskItemCount
    }
}

function startGettingSubtaskDetail() {
    return {
        type: START_LOADING_SUBTASK_DETAIL
    }
}

function finishGettingSubtaskDetail(res) {
    return {
        type: FINISH_LOADING_SUBTASK_DETAIL,
        subtaskDetail: res
    }
}

export function getSubtaskDetail(params) {
    return dispatch => {
        dispatch(startGettingSubtaskDetail())
        subtask.query(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishGettingSubtaskDetail(res.data.subTaskDetail))
                } else {
                    dispatch(handleError())
                }
            })
    }
}

export function annotateTask(state = initState, action) {
    switch (action.type) {
        case START_LOADING_TASK_ITEM:
            return {
                ...state,
                taskItemIsFetching: true
            }
        case FINISH_LOADING_TASK_ITEM:
            return {
                ...state,
                taskItemIsFetching: false,
                taskItem: action.taskItem
            }
        case START_LOADING_SUBTASK_DETAIL:
            return {
                ...state,
                subtaskDetailIsFetching: true
            }
        case FINISH_LOADING_SUBTASK_DETAIL:
            return {
                ...state,
                subtaskDetailIsFetching: false,
                subtaskDetail: action.subtaskDetail
            }
        case UPDATE_SUBTASK_DETAIL_MARKED_COUNT:
            return {
                ...state,
                subtaskDetail: {
                    ...state.subtaskDetail,
                    markedCount: action.markedCount
                }
            }
        case UPDATE_SUBTASK_DETAIL_PROCESS_AUDIO_DURATION:
            return {
                ...state,
                subtaskDetail: {
                    ...state.subtaskDetail,
                    processAudioDuration: action.processAudioDuration
                }
            }
        case UPDATE_SUBTASK_DETAIL_TOTAL_NUMBER_OF_TASKS:
            return {
                ...state,
                subtaskDetail: {
                    ...state.subtaskDetail,
                    taskItemCount: action.taskItemCount
                }
            }
        case HANDLE_ERROR:
            return {
                ...state,
                taskItemIsFetching: false,
                historyListIsFetching: false,
                favoriteListIsFetching: false,
                subtaskDetailIsFetching: false
            }
        case CLEAR_TASK:
            return {
                ...state,
                subtaskDetail: null,
                subtaskDetailIsFetching: true
            }
        default:
            return state
    }
}