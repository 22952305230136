import statisticsService from '../../service/services/statistics'
import {handleResultCode} from "../../service/service"
import axios from "axios"

const START_LOADING_AUDIO_STATISTICS_TABLE = 'START_LOADING_AUDIO_STATISTICS_TABLE',
    FINISH_LOADING_AUDIO_STATISTICS_TABLE = 'FINISH_LOADING_AUDIO_STATISTICS_TABLE',
    START_EXPORT_AUDIO_STATISTICS_TABLE = 'START_EXPORT_AUDIO_STATISTICS_TABLE',
    FINISH_EXPORT_AUDIO_STATISTICS_TABLE = 'FINISH_EXPORT_AUDIO_STATISTICS_TABLE'

const initState = {
    audioStatisticsTableIsFetching: false,
    audioStatisticsTableIsExporting: false,
    markMainTaskTable: {},
    markPersonTable: {},
    markTaskItemTable: {},
    qualityMainTaskTable: {},
    qualityPersonTable: {},
    qualityTaskItemTable: {}
}

function startGettingTableData() {
    return {
        type: START_LOADING_AUDIO_STATISTICS_TABLE
    }
}

export function getTableData(tableName, operationType, queryData) {
    return dispatch => {
        dispatch(startGettingTableData())
        statisticsService.queryAudioStatistics(operationType, queryData)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    let {statisticsDataList, pageIndex, totalItemCount, totalPageCount} = res.data
                    dispatch(finishGettingTableData(tableName, {
                        statisticsDataList: statisticsDataList.map((d, index)=>{return {...d, indexId: tableName+index}}),
                        pageIndex: pageIndex - 1,
                        totalItemCount,
                        totalPageCount,
                    }))
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

function finishGettingTableData(tableName, tableData) {
    return {
        type: FINISH_LOADING_AUDIO_STATISTICS_TABLE,
        tableName,
        tableData
    }
}

function startExportTableData() {
    return {
        type: START_EXPORT_AUDIO_STATISTICS_TABLE
    }
}


export function exportFile(data) {
    return dispatch => {
        dispatch(startExportTableData())
        axios({
            url: process.env.DOMAIN_NAME+'/xbizapi/226/statistics',
            method: 'post',
            data,
            responseType: 'blob',
            headers: {'Authorization': `Bearer ${window.localStorage.getItem("leyan_data_annotation_platform_auth")}`},
        }).then((response)=>{
            if (!response) {
                dispatch(finishExportTableData())
                return
            }
            const blob = new Blob([response.data])
            let fileName = response.headers["content-disposition"]?response.headers["content-disposition"].split("filename=")[1]:'export_sql.xlsx'
            if ('download' in document.createElement('a')) { // 非IE下载
                const elink = document.createElement('a')
                elink.download = fileName
                elink.style.display = 'none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
                dispatch(finishExportTableData())
            } else { // IE10+下载
                navigator.msSaveBlob(blob, fileName)
                dispatch(finishExportTableData())
            }
        })
    }

}

function finishExportTableData() {
    return {
        type: FINISH_EXPORT_AUDIO_STATISTICS_TABLE
    }
}

export function statistics(state = initState, action) {
    switch (action.type) {
        case START_LOADING_AUDIO_STATISTICS_TABLE:
            return {
                ...state,
                audioStatisticsTableIsFetching: true
            }
        case FINISH_LOADING_AUDIO_STATISTICS_TABLE:
            return {
                ...state,
                audioStatisticsTableIsFetching: false,
                [action.tableName]: action.tableData
            }
        case START_EXPORT_AUDIO_STATISTICS_TABLE:
            return {
                ...state,
                audioStatisticsTableIsExporting: true
            }
        case FINISH_EXPORT_AUDIO_STATISTICS_TABLE:
            return {
                ...state,
                audioStatisticsTableIsExporting: false
            }
        default:
            return state
    }
}