import axios from '../service'

const url = '/xbizapi/226/user_auth'

var userAuth = {}

const apis = [{
    name: "login",
    operationType: "GEN_TOKEN"
},{
    name: "getInfo",
    operationType: "GET_ACCESS_INFO"
},{
    name: "loginByKey",
    operationType: "GET_TOKEN_BY_KEY"
}]

apis.map((api) => {
    userAuth[api.name] = function (data) {
        return axios(url, {
            data: Object.assign({}, {operationType: api.operationType}, data),
        })
    }
})

export default userAuth