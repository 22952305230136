import axios from '../service'

const url = '/xbizapi/226/maintask'
const detailUrl = '/xbizapi/226/maintaskdetail'
const assignUrl = '/xbizapi/226/maintaskassign'
const cfgUrl = '/xbizapi/226/mconfig'

var mainTask = {}

const apis = [{
    name: "query",
    operationType: "QUERY_MAINTASKLIST"
},{
    name: "create",
    operationType: "CREATE_MAINTASK"
},{
    name: "queryFlow",
    operationType: "QUERY_FLOWTEMPLATE_LIST"
},{
    name: "split",
    operationType: "SPLIT_DATA_MAINTASK"
},{
    name: "updateHandbook",
    operationType: "UPDATE_HANDBOOK"
},{
    name: "export",
    operationType: "EXPORT_ANNO_RESULT"
}, {
    // 会传operationType覆盖掉此处掉null
    name: 'changeEnabledStatus',
    operationType: null
},{
    name: "updateTask",
    operationType: "UPDATE_MAINTASK"
}]

apis.map((api) => {
    mainTask[api.name] = function (data) {
        return axios(url, {
            data: Object.assign({}, {operationType: api.operationType}, data)
        })
    }
})

const detailApis = [{
    name: "queryDetail",
    operationType: "QUERY_MAINTASK_DETAIL"
},{
    name: "queryAssign",
    operationType: "QUERY_ASSIGNTASK_DETAIL"
}]

detailApis.map((api) => {
    mainTask[api.name] = function (data) {
        return axios(detailUrl, {
            data: Object.assign({}, {operationType: api.operationType}, data)
        })
    }
})

const assignApis = [{
    name: "assign",
    operationType: "ASSIGNSUBTASK_MAINTASK"
},{
    name: "reassign",
    operationType: "REASSIGNSUBTASK_MAINTASK"
},{
    name: "checkAssign",
    operationType: "CHECK_ASSIGNSUBTASK_MAINTASK"
},{
    name: "checkReassign",
    operationType: "CHECK_REASSIGNSUBTASK_MAINTASK"
},{
    name: "getTaskAudioLength",
    operationType: "ASSIGNSUBTASK_MAINTASK_CACULATE"
},{
    name: "getReassignTaskAudioLength",
    operationType: "REASSIGNSUBTASK_MAINTASK_CACULATE"
}]

assignApis.map((api) => {
    mainTask[api.name] = function (data) {
        return axios(assignUrl, {
            data: Object.assign({}, {operationType: api.operationType}, data)
        })
    }
})

const cfgApis = [{
  name: "getAudioAnnotationType",
  operationType: "QUERY_MCONFIG",
  configKeys:["audioAnnotationType"]
}]

cfgApis.map((api) => {
  mainTask[api.name] = function () {
    return axios(cfgUrl, {
      data: {operationType: api.operationType, configKeys: api.configKeys}
    })
  }
})


export default mainTask
