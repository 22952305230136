/*eslint-disable*/
import axios from '../service'



let roleManageService = {}

const apis = [
    {
        name: 'getFuncList',
        operationType: 'LIST_FUNCTION',
        url: '/xbizapi/226/function'
    },
    {
        name: 'getRoleList',
        operationType: 'LIST_ROLE_INFO',
        url: '/xbizapi/226/role'
    },
    {
        name: 'getRoleInfo',
        operationType: 'CHECK_ROLE_INFO',
        url: '/xbizapi/226/role'
    },
    {
        name: 'submitRoleInfo',
        operationType: 'SET_ROLE_FUNCTION',
        url: '/xbizapi/226/role'
    },
    {
        name: 'getQueryRoleList',
        operationType: 'LIST_ROLE_INFO',
        url: '/xbizapi/226/role'
    },

]

apis.map((api) => {
    roleManageService[api.name] = (data) => {
        return axios(api.url, {
            data: Object.assign({}, {
                operationType: api.operationType,
            }, data),
        })
    }
})

export default roleManageService
