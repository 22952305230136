import axios from '../service'

var booksManagement = {}

const apis = [{
    url: '/xbizapi/226/textbook',
    name: "getSectionBlock",
    operationType: "GET_SECTION_BLOCK"
},{
    url: '/xbizapi/226/dict',
    name: "getSystemDict",
    operationType: "GET_SYSTEM_DICT"
},{
    url: '/xbizapi/226/textbook',
    name: "createBooks",
    operationType: "SAVE_TEXT_BOOK_MATERIAL_MATERIAL"
},{
    url: '/xbizapi/226/textbook',
    name: "query",
    operationType: "GET_TEXT_BOOK_MATERIAL"
},{
    url: '/xbizapi/226/textbook',
    name: "getDetail",
    operationType: "GET_TEXT_BOOK_MATERIAL_DETAILS"
},{
    url: '/xbizapi/226/textbook',
    name: "updateBooks",
    operationType: "UPDATE_TEXT_BOOK_MATERIAL_DETAILS"
},{
    url: '/xbizapi/226/textbook',
    name: "getQuestion",
    operationType: "GET_QUESTION_CONFIG"
}, {
    url: '/xbizapi/226/textbook',
    name: "textBookStatus",
    operationType: "SUBMIT_MATERIAL_INSTANCE"
}, {
    url: '/xbizapi/226/textbook',
    name: "deleteTextBookDetaile",
    operationType: "DELETE_TEXT_BOOK_MATERIAL_DETAILS"
}, {
    url: '/xbizapi/226/textbook',
    name: "bulkRelease",
    operationType: "BULK_RELEASE_MATERIAL_INSTANCE"
}]

apis.map((api) => {
    booksManagement[api.name] = function (data) {
        return axios(api.url, {
            data: Object.assign({}, {operationType: api.operationType}, data),
        })
    }
})

export default booksManagement