import axios from '../service'

//时期设置URL
const url = '/xbizapi/226/period'

//时期排布设置URL
const timeAssignUrl = '/xbizapi/226/perioddate'

var timeSettingListService = {}

var timeAssignSettingListService = {}

const apis = [{
    name: "query",
    operationType: "QUERY_PERIOD_LIST"
},{
    name: "deleteTimeSetting",
    operationType: "DEL_PERIOD"
},{
    name: 'addTime',
    operationType: 'ADD_PERIOD'
}, {
    name: 'update',
    operationType: 'UPDATE_PERIOD'
}]


const timeAssignApis = [
    {
        name: 'addTimeAssign',
        operationType: 'ADD_PERIOD_DATE'
    },{
        name: 'getTimeAssign',
        operationType: 'QUERY_ALL_PERIOD_DATE_LIST'
    },
    {
        name: 'getCalenderDays',
        operationType: 'QUERY_PERIOD_DATE_LIST'
    },{
        name: 'updateTimeAssign',
        operationType: 'UPDATE_PERIOD_DATE'
    },{
        name: 'getValidPeriodDate',
        operationType: 'QUERY_VALID_PERIOD_DATE_LIST'
    }
]



apis.map((api) => {
    timeSettingListService[api.name] = function (data) {
        return axios(url, {
            data: Object.assign({}, {operationType: api.operationType}, data)
        })
    }
})

timeAssignApis.map((api) => {
    timeAssignSettingListService[api.name] = function (data) {
        return axios(timeAssignUrl, {
            data: Object.assign({}, {operationType: api.operationType}, data)
        })
    }
})

export {timeSettingListService, timeAssignSettingListService}