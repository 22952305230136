import {Modal} from 'antd'
import axios from 'axios'

const instance = axios.create({
    method: 'post',
    baseURL: process.env.DOMAIN_NAME,
    headers: {'Content-Type': 'application/json; charset=UTF-8'}
})

instance.interceptors.request.use(
    (config) => {
        const token = window.localStorage.getItem("leyan_data_annotation_platform_auth")
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
})

instance.interceptors.response.use(
    (response) => {
        return response
    }, (error) => {
        if(error.response.status===403) {
            Modal.error({
                title: error.response.data.resultMessage,
                zIndex: 10001,
                onOk() {
                    // window.location.reload()
                }
            })
        }
        return Promise.reject(error)
    })

export function handleResultCode(response, cb) {
    if(response.status && response.data) {
        switch (response.data.resultCode) {
            case 0:
                return {success: true,data: response.data}
            case -1:
            case 1:
            case 110:
            case 300:
            case 403: {
                Modal.error({
                    title: response.data.resultMessage,
                    zIndex: 10001,
                    onOk() {
                        // window.location.reload()
                        if(cb) {
                            cb()
                        }
                    }
                })
                return {success: false, data: response.data}
            }
            case 400: {
                Modal.error({
                    title: response.data.resultMessage,
                    zIndex: 10001,
                    onOk() {
                        window.location.reload()
                    }
                })
                return {success: false, data: response.data}
            }
            case 401:
            case 100: {
                window.localStorage.removeItem('leyan_data_annotation_copy_book_info')
                window.localStorage.removeItem("leyan_data_annotation_platform_auth")
                window.localStorage.removeItem("leyan_data_annotation_platform_auth_fns")
                window.location.reload()
                break
            }
            default:
                if(cb) {
                    cb()
                }
                return {success: false,
                    data: response.data}
        }
    } else {
        return {
            success: false,
            data: response
        }
    }
}

export default instance
