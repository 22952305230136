import dataset from '../../service/services/dataset'
import {handleResultCode} from "../../service/service"

const HANDLE_ERROR = 'HANDLE_ERROR',
    START_LOADING_DATASET = 'START_LOADING_DATASET',
    FINISH_LOADING_DATASET = 'FINISH_LOADING_DATASET',
    START_COUNTING_DATASET = 'START_COUNTING_DATASET',
    FINISH_COUNTING_DATASET = 'FINISH_COUNTING_DATASET'

const initState = {
    datasetIsFetching: false,
    datasetIsCounting: false,
    datasetList: [],
    datasetCount: 0
}

function startGettingDataset() {
    return {
        type: START_LOADING_DATASET
    }
}

function finishGettingDataset(res) {
    return {
        type: FINISH_LOADING_DATASET,
        dataset: res
    }
}

function startCountingDataset() {
    return {
        type: START_COUNTING_DATASET
    }
}

function finishCountingDataset(res) {
    return {
        type: FINISH_COUNTING_DATASET,
        datasetCount: res
    }
}

function handleError() {
    return {
        type: HANDLE_ERROR
    }
}

export function getDataset(scenarioId, dataType) {
    return dispatch => {
        dispatch(startGettingDataset())
        dataset.query({
            // scenarioId,
            dataType
        })
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishGettingDataset(res.data.dataSetDTOList))
                } else {
                    dispatch(handleError())
                }
            })
    }
}

export function countDataset(params) {
    return dispatch => {
        dispatch(startCountingDataset())
        dataset.count(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishCountingDataset(res.data.dataSetCount))
                } else {
                    dispatch(handleError())
                }
            })
    }
}


export function datasets(state = initState, action) {
    switch (action.type) {
        case START_LOADING_DATASET:
            return {
                ...state,
                datasetIsFetching: true
            }
        case FINISH_LOADING_DATASET:
            return {
                ...state,
                datasetIsFetching: false,
                datasetList: action.dataset
            }
        case START_COUNTING_DATASET:
            return {
                ...state,
                datasetIsCounting: true
            }
        case FINISH_COUNTING_DATASET:
            return {
                ...state,
                datasetIsCounting: false,
                datasetCount: action.datasetCount
            }
        case HANDLE_ERROR:
            return {
                ...state,
                datasetIsCounting: false,
                datasetIsFetching: false
            }
        default:
            return state
    }
}