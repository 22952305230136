import axios from '../service'

var taskItem = {}

const apis = [{
    url: '/xbizapi/226/taskitem',
    name: "get",
    operationType: "GET_SUBTASKITEMS_BYPAGE"
},{
    url: '/xbizapi/226/taskitem_partialorder',
    name: "partialOrder",
    operationType: "MARK_TASKITEM"
},{
    url: '/xbizapi/226/taskitem_expansion',
    name: "expansion",
    operationType: "MARK_TASKITEM"
},{
    url: '/xbizapi/226/taskitem_manual',
    name: "manual",
    operationType: "MARK_TASKITEM"
},{
    url: '/xbizapi/226/taskitem_eduaudio',
    name: "eduaudio",
    operationType: "MARK_TASKITEM"
},{
    url: '/xbizapi/226/taskitem_audition',
    name: "audition",
    operationType: "MARK_TASKITEM"
},{
    url: '/xbizapi/226/taskitem_vad',
    name: "vadTaskitem",
    operationType: "MARK_TASKITEM"
}]

apis.map((api) => {
    taskItem[api.name] = function (data) {
        return axios(api.url, {
            data: Object.assign({}, {operationType: api.operationType}, data),
        })
    }
})

export default taskItem