import React, {Component, Fragment} from 'react'
import propTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {Sider} from 'antd/lib/layout'
import 'antd/lib/layout/style/index.less'
import Menu from 'antd/lib/menu'
import 'antd/lib/menu/style/index.less'
import Icon from 'antd/lib/icon'
import 'antd/lib/icon/style/index.less'
import loginSvg from '../../resources/svg/login.svg'
import timeTabel from '../../resources/svg/timeTabel.svg'
// import memberManageSvg from '../../resources/svg/memberManage.svg'
import sAndtSvg from '../../resources/svg/s&t.svg'
import myTasksSvg from '../../resources/svg/myTasks.svg'
import './index.css'
const { SubMenu } = Menu
import {DATA_SET_MANAGEMENT, DATA_STATISTICS, DUTY_TABLE, isShowViewWithAuth, MY_TASK, SCENE_TASK_SUB, BOOK_SUB, USER_MANAGEMENT} from "../../utils"

class Nav extends Component {
    constructor(props) {
        super(props)
        this.computeUser = this.computeUser.bind(this)
    }
    computeUser() {
        let isAdmin = false
        let {accessInfos} = this.props
        for(let a of accessInfos) {
            if(a.roleName==="admin") {
                isAdmin = true
            }
        }
        return isAdmin
    }

    render() {
        return(
            <Sider collapsible collapsed={this.props.collapsed} onCollapse={this.props.onCollapse} width={240} className="sider">
                <div style={{textAlign: 'center'}}>
                    {this.props.collapsed?<img src={require('../../resources/images/logo-small.png')} className="logo-small"/>:<img src={require('../../resources/images/logo.png')} className="logo"/>}
                </div>
                <Menu theme="dark" selectedKeys={[this.context.selectedKeys]} mode="inline">
                    {/*<Menu.Item key="1">*/}
                        {/*<Link to="/home">*/}
                            {/*<Icon component={memberManageSvg} style={{fontSize: '22px'}}/>*/}
                            {/*<span className="alignCenter">用户管理</span>*/}
                        {/*</Link>*/}
                    {/*</Menu.Item>*/}
                    {isShowViewWithAuth(SCENE_TASK_SUB) ?
                        <Menu.Item key="2">
                            <Link to="/scenarios">
                                <div className="textCenter">
                                    <Icon component={sAndtSvg} style={{fontSize: '22px'}}/>
                                    <span className="nav-item-title">场景任务</span>
                                </div>
                            </Link>
                        </Menu.Item> : null}
                    {isShowViewWithAuth(MY_TASK) ?
                        <Menu.Item key="3">
                            <Link to="/myTasks">
                                <div className="textCenter">
                                    <Icon component={myTasksSvg} style={{fontSize: '22px'}} />
                                    <span className="nav-item-title">我的任务</span>
                                </div>
                            </Link>
                        </Menu.Item>
                        : null
                    }
                    {isShowViewWithAuth(DUTY_TABLE)
                        ? <Menu.Item key="4">
                            <Link to="/dutyTable" replace>
                                <div className="textCenter">
                                    <Icon component={timeTabel} style={{fontSize: '22px'}}/>
                                    <span className="nav-item-title">值班表</span>
                                </div>
                            </Link>
                        </Menu.Item>
                        : null
                    }
                  {
                    isShowViewWithAuth(DATA_STATISTICS) ?
                        <SubMenu
                            className={this.props.collapsed?'nav-sub-menu-collapsed':'nav-sub-menu'}
                            key="5"
                            title={<div className="textCenter">
                              <Icon type="area-chart" style={{fontSize: '22px'}}/>
                              <span className="alignCenter fs-16">统计数据</span>
                            </div>}
                        >
                          <Menu.Item key="51">
                            <Link to="/eduAudioAnnotateStatistics">
                              <span className="alignCenter">标注质量统计</span>
                            </Link>
                          </Menu.Item>
                          <Menu.Item key="52">
                            <Link to="/eduAudioQualityTestStatistics">
                              <span className="alignCenter">质检结果统计</span>
                            </Link>
                          </Menu.Item>
                        </SubMenu>
                        : null
                    }
                    {isShowViewWithAuth(DATA_SET_MANAGEMENT)
                        ? <Menu.Item key="6">
                            <Link to="/dataSetManagement">
                                <div className="textCenter">
                                    <Icon type="database" style={{fontSize: '22px'}}/>
                                    <span className="nav-item-title">数据集管理</span>
                                </div>
                            </Link>
                        </Menu.Item>
                        : null
                    }
                    {
                        isShowViewWithAuth(BOOK_SUB)
                        ? <Menu.Item key="7">
                            <Link to="/resource/bookManagement">
                                <div className="textCenter">
                                    <Icon type="read" style={{fontSize: '22px'}}/>
                                    <span className="nav-item-title">资源管理</span>
                                </div>
                            </Link>
                        </Menu.Item>
                        : null
                    }
                    {isShowViewWithAuth(USER_MANAGEMENT)
                        ?
                        <Menu.Item key="userManage">
                            <Link to="/userManage" replace>
                                <div className="textCenter">
                                    <Icon component={timeTabel} style={{fontSize: '22px'}}/>
                                    <span className="nav-item-title">用户管理</span>
                                </div>
                        </Link>
                        </Menu.Item>
                        : null
                    }
                </Menu>
                <div className="logoutBox"
                     onClick={()=> {
                        window.localStorage.removeItem('leyan_data_annotation_copy_book_info')
                        window.localStorage.removeItem("leyan_data_annotation_platform_auth")
                        window.localStorage.removeItem("leyan_data_annotation_platform_auth_fns")
                        window.location.reload()
                     }}
                >
                    {this.props.collapsed?<Icon component={loginSvg}/>:<Fragment>
                        <p>
                            <span style={{marginRight: '10px'}}>退出登录</span>
                            <Icon component={loginSvg}/>
                        </p>
                        <p>{this.props.userDetailInfo.username}</p>
                    </Fragment>}

                </div>
            </Sider>)
    }
}

Nav.contextTypes = {
    selectedKeys: propTypes.string
}

Nav.propTypes = {
    accessInfos: propTypes.array,
    userDetailInfo: propTypes.object,
    collapsed: propTypes.bool,
    onCollapse: propTypes.func,
}

export default Nav
