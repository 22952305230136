import {timeSettingListService, timeAssignSettingListService}  from '../../service/services/timeSettingListService'
import {handleResultCode} from "../../service/service"
import {message} from 'antd'

const START_LOADING_TIME_SETTING_LIST = 'START_LOADING_TIME_SETTING_LIST',
    FINISH_LOADING_TIME_SETTING_LIST = 'FINISH_LOADING_TIME_SETTING_LIST',
    START_DELETING_TIME_SETTING = 'START_DELETING_TIME_SETTING',
    FINISH_DELETING_TIME_SETTING = 'FINISH_DELETING_TIME_SETTING',
    START_SUBMIT_TIME_SETTING = 'START_SUBMIT_TIME_SETTING',
    FINISH_SUBMIT_TIME_SETTING = 'FINISH_SUBMIT_TIME_SETTING',
    START_UPDATE_GET_TIME_SETTING = 'START_UPDATE_GET_TIME_SETTING',
    FINISH_UPDATE_GET_TIME_SETTING='FINISH_UPDATE_GET_TIME_SETTING',
    START_LOADING_TIME_ASSIGN_SETTING_LIST='START_LOADING_TIME_ASSIGN_SETTING_LIST',
    FINISH_LOADING_TIME_ASSIGN_SETTING_LIST='FINISH_LOADING_TIME_ASSIGN_SETTING_LIST',
    START_SUBMIT_TIME__ASSIGN_SETTING = 'START_SUBMIT_TIME__ASSIGN_SETTING',
    FINISH_SUBMIT_TIME_ASSIGN_SETTING = 'FINISH_SUBMIT_TIME_ASSIGN_SETTING',
    START_LOADING_CALENDER_DAYS = 'START_LOADING_CALENDER_DAYS',
    FINISH_LOADING_CALENDER_DAYS = 'FINISH_LOADING_CALENDER_DAYS',
    START_UPDATE_PERIOD_DATE = 'START_UPDATE_PERIOD_DATE',
    FINISH_UPDATE_PERIOD_DATE = 'FINISH_UPDATE_PERIOD_DATE',
    START_LOADING_VALID_PERIOD_DATE = 'START_LOADING_VALID_PERIOD_DATE',
    FINISH_LOADING_VALID_PERIOD_DATE = 'FINISH_LOADING_VALID_PERIOD_DATE'



const initState = {
    timeSettingListIsFetching: false,
    timeAssignSettingListIsFetching: false,
    pageIndex: 1,
    pageSize: 10,
    totalPageCount: 1,
    totalItemCount: 1,
    timeSettingList: [],
    submitSuccess: false,
    periodDateList: [],
    periodInfos: [],
    calenderDays: [],
    validPeriodDate: [],
}
/**
 * 获取时期设置列表
 **/

export function startGettingTimeSettingList() {
    return {
        type: START_LOADING_TIME_SETTING_LIST
    }
}

export function finishGettingTimeSettingList(res) {
    return {
        type: FINISH_LOADING_TIME_SETTING_LIST,
        pageIndex: res.pageIndex,
        pageSize: res.pageSize,
        totalPageCount: res.totalPageCount,
        totalItemCount: res.totalItemCount,
        periodInfos: res.periodInfos
    }
}

export function getTimeSettingList(params) {
    return dispatch => {
        dispatch(startGettingTimeSettingList())
        timeSettingListService.query(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    let {pageIndex, pageSize, periodInfos, totalItemCount, totalPageCount} = res.data
                    dispatch(finishGettingTimeSettingList({pageIndex, pageSize, periodInfos, totalItemCount, totalPageCount}))
                }
            })
            .catch(()=> {
            })
    }
}


//获取某个时期排布的全部日期

export function startGettingValidPeriodDate() {
    return {
        type: START_LOADING_VALID_PERIOD_DATE
    }
}

export function finishGettingValidPeriodDate(res) {
    return {
        type: FINISH_LOADING_VALID_PERIOD_DATE,
        pageIndex: res.pageIndex,
        pageSize: res.pageSize,
        totalPageCount: res.totalPageCount,
        totalItemCount: res.totalItemCount,
        validPeriodDate: res.periodDateInfo.dateList
    }
}

export function getValidPeriodDate(params, cb) {
    return dispatch => {
        dispatch(startGettingValidPeriodDate())
        timeAssignSettingListService.getValidPeriodDate(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {

                    let {pageIndex, pageSize, periodDateInfo, totalItemCount, totalPageCount} = res.data
                    cb(periodDateInfo.dateList)
                    dispatch(finishGettingValidPeriodDate({pageIndex, pageSize, periodDateInfo, totalItemCount, totalPageCount}))
                }
            })
            .catch(()=> {
            })
    }
}


export function timeSettingListReducer(state = initState, action) {
    switch (action.type) {
        case START_LOADING_TIME_SETTING_LIST:
            return {
                ...state,
                timeSettingListIsFetching: true
            }
        case FINISH_LOADING_TIME_SETTING_LIST:
            return {
                ...state,
                timeSettingListIsFetching: false,
                pageIndex: action.pageIndex,
                pageSize: action.pageSize,
                periodInfos: action.periodInfos,
                totalItemCount: action.totalItemCount,
                totalPageCount: action.totalPageCount
            }
        case START_SUBMIT_TIME__ASSIGN_SETTING:
            return {
                ...state,
                timeSettingIsFetching: true
            }
        case FINISH_SUBMIT_TIME_ASSIGN_SETTING:
            return {
                ...state,
                timeSettingIsFetching: false,
                periodDateList: action.periodDateList
            }

        case START_LOADING_TIME_ASSIGN_SETTING_LIST:
            return {
                ...state,
                timeAssignSettingListIsFetching: true
            }
        case FINISH_LOADING_TIME_ASSIGN_SETTING_LIST:
            return {
                ...state,
                timeAssignSettingListIsFetching: false,
                pageIndex: action.pageIndex,
                pageSize: action.pageSize,
                periodDateList: action.periodDateList,
                totalItemCount: action.totalItemCount,
                totalPageCount: action.totalPageCount
            }

        case START_LOADING_CALENDER_DAYS:
            return {
                ...state,
            }
        case FINISH_LOADING_CALENDER_DAYS:
            return {
                ...state,
                calenderDays: action.calenderDays,
            }

        case START_UPDATE_PERIOD_DATE:
            return {
                ...state,
            }
        case FINISH_UPDATE_PERIOD_DATE:
            return {
                ...state,
            }
        case START_LOADING_VALID_PERIOD_DATE:
            return {
                ...state,
            }
        case FINISH_LOADING_VALID_PERIOD_DATE:
            return {
                ...state,
                validPeriodDate: action.validPeriodDate,
            }



        default:
            return state
    }
}

/**
 * 时期的删除
 **/

export function deleteTimeSetting(params) {
    return dispatch => {
        dispatch(startDeleteTimeSetting())
        timeSettingListService.deleteTimeSetting(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                dispatch(finishDeleteTimeSetting())
                if(res.success) {
                    message.success('删除成功')
                    dispatch(getTimeSettingList())
                }
            })
            .catch(()=> {
            })
    }
}

function startDeleteTimeSetting() {
    return {
        type: START_DELETING_TIME_SETTING
    }
}

function finishDeleteTimeSetting() {
    return {
        type: FINISH_DELETING_TIME_SETTING
    }
}


/**
 * 时期的提交
 **/

export function addTimeSetting(params, successCb) {
    return dispatch => {
        dispatch(startSubmitTimeSetting())
        timeSettingListService.addTime(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                dispatch(finishSubmitTimeSetting())
                if(res.success) {
                    message.success('添加时期成功')
                    successCb()
                    dispatch(getTimeSettingList())
                }
            })
            .catch(()=> {
            })
    }
}

function startSubmitTimeSetting() {
    return {
        type: START_SUBMIT_TIME_SETTING
    }
}

function finishSubmitTimeSetting() {
    return {
        type: FINISH_SUBMIT_TIME_SETTING
    }
}


/**
 * 时期排布的添加
 **/

export function addTimeAssignSetting(params, periodDateInfoObj, successCb) {
    return dispatch => {
        dispatch(startAddTimeAssignSetting())
        timeAssignSettingListService.addTimeAssign(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    dispatch(finishAddTimeAssignSetting())
                    message.success('添加时期排布成功')
                    successCb()
                    dispatch(getTimeAssignSettingList(periodDateInfoObj))
                }
            })
            .catch(()=> {
            })
    }
}

function startAddTimeAssignSetting() {
    return {
        type: START_SUBMIT_TIME_SETTING
    }
}

function finishAddTimeAssignSetting() {
    return {
        type: FINISH_SUBMIT_TIME_SETTING
    }
}


/**
 * 时期排布的编辑
 **/

export function updateTimeAssignSetting(params, periodDateInfoObj, successCb) {
    return dispatch => {
        dispatch(startUpdateTimeAssignSetting())
        timeAssignSettingListService.updateTimeAssign(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    dispatch(finishUpdateTimeAssignSetting())
                    message.success('编辑时期排布成功')
                    successCb()
                    dispatch(getTimeAssignSettingList(periodDateInfoObj))
                }
            })
            .catch(()=> {
            })
    }
}

function startUpdateTimeAssignSetting() {
    return {
        type: START_UPDATE_PERIOD_DATE
    }
}

function finishUpdateTimeAssignSetting() {
    return {
        type: FINISH_UPDATE_PERIOD_DATE
    }
}


/**
 * 时期的修改
 **/

export function updateTimeSetting(params, successCb) {
    return dispatch => {
        dispatch(startUpdateTimeSetting())
        timeSettingListService.update(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                dispatch(finishUpdateTimeSetting())
                if(res.success) {
                    message.success('更新时期成功')
                    successCb()
                    dispatch(getTimeSettingList())
                }
            })
            .catch(()=> {
            })
    }
}

function startUpdateTimeSetting() {
    return {
        type: START_UPDATE_GET_TIME_SETTING
    }
}

function finishUpdateTimeSetting() {
    return {
        type: FINISH_UPDATE_GET_TIME_SETTING
    }
}



/**
 * 获取时期排布情况列表
 **/

export function startGettingTimeAssignSettingList() {
    return {
        type: START_LOADING_TIME_ASSIGN_SETTING_LIST
    }
}

export function finishGettingTimeAssignSettingList(res) {
    return {
        type: FINISH_LOADING_TIME_ASSIGN_SETTING_LIST,
        pageIndex: res.pageIndex,
        pageSize: res.pageSize,
        totalPageCount: res.totalPageCount,
        totalItemCount: res.totalItemCount,
        periodDateList: res.periodDateList
    }
}

export function getTimeAssignSettingList(params) {
    return dispatch => {
        dispatch(startGettingTimeAssignSettingList())
        timeAssignSettingListService.getTimeAssign(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    let {pageIndex, pageSize, periodDateList, totalItemCount, totalPageCount} = res.data
                    dispatch(finishGettingTimeAssignSettingList({pageIndex, pageSize, periodDateList, totalItemCount, totalPageCount}))
                }
            })
            .catch(()=> {
            })
    }
}


//点击日历图标时获取此时期排布一个月的数据

export function startGettingCalenderDays() {
    return {
        type: START_LOADING_CALENDER_DAYS
    }
}

export function finishGettingCalenderDays(res) {
    return {
        type: FINISH_LOADING_CALENDER_DAYS,
        calenderDays: res.periodDateInfo.dateList
    }
}

export function getCalenderDays(params) {
    return dispatch => {
        dispatch(startGettingCalenderDays())
        timeAssignSettingListService.getCalenderDays(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    let {pageIndex, pageSize, periodDateInfo, totalItemCount, totalPageCount} = res.data
                    dispatch(finishGettingCalenderDays({pageIndex, pageSize, periodDateInfo, totalItemCount, totalPageCount}))
                }
            })
            .catch(()=> {
            })
    }
}