import axios from '../service'

const url = '/xbizapi/226/scenario'

var scenario = {}

const apis = [{
    name: "getBasicData",
    operationType: "GET_BASIC_DATA"
},{
    name: "create",
    operationType: "CREATE_SCENARIO"
},{
    name: "update",
    operationType: "UPDATE_SCENARIO"
},{
    name: "query",
    operationType: "QUERY_SCENARIO"
},{
    name: "delete",
    operationType: "DELETE_SCENARIO"
}]

apis.map((api) => {
    scenario[api.name] = function (data) {
        return axios(url, {
            data: Object.assign({}, {operationType: api.operationType}, data)
        })
    }
})

export default scenario