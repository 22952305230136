import mainTask from '../../service/services/mainTask'
import {handleResultCode} from "../../service/service"
import {Modal} from 'antd'
import {push} from "react-router-redux"

const START_UPDATE_TASK = 'START_UPDATE_TASK',
    FINISH_UPDATE_TASK = 'FINISH_UPDATE_TASK'
const initState = {
    taskIsCreating: false
}

function startUpdateTask() {
    return {
        type: START_UPDATE_TASK
    }
}

function finishUpdateTask() {
    return {
        type: FINISH_UPDATE_TASK
    }
}

export function updateTask(params) {
    return dispatch => {
        dispatch(startUpdateTask())
        mainTask.updateTask(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    dispatch(finishUpdateTask())
                    Modal.success({
                        title: '更新任务成功！',
                        onOk() {
                            dispatch(push('/scenarios/'+params.mainTaskDTO.scenarioId))
                        }
                    })
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

export function taskUpdate(state = initState, action) {
    switch (action.type) {
        case START_UPDATE_TASK:
            return {
                ...state,
                taskIsCreating: true
            }
        case FINISH_UPDATE_TASK:
            return {
                ...state,
                taskIsCreating: false
            }
        default:
            return state
    }
}
