import axios from '../service'

const url = '/xbizapi/226/datasetimport'

var datasetimport = {}

const apis = [{
    name: "importQueryByPage",
    operationType: "IMPORT_QUERY_BY_PAGE"
},{
    name: "DataSetImport",
    operationType: "DATA_SET_IMPORT"
}]

apis.map((api) => {
    datasetimport[api.name] = function (data) {
        return axios(url, {
            data: Object.assign({}, {operationType: api.operationType}, data)
        })
    }
})

export default datasetimport