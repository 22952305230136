import axios from '../service'

const url = '/xbizapi/226/userandright'

var users = {}

const apis = [{
    name: "query",
    operationType: "CHECK_USER_PAGE_INFO"
}]

apis.map((api) => {
    users[api.name] = function (data) {
        return axios(url, {
            data: Object.assign({}, {operationType: api.operationType}, data),
        })
    }
})

export default users