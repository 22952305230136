import axios from '../service'

const url = '/xbizapi/226/onlineLearning'

var onlineLearning = {}

const apis = [{
    name: "getItem",
    operationType: "QUERY_DATA"
},{
    name: "save",
    operationType: "SAVE_DATA"
},{
    name: "submit",
    operationType: "SUBMIT_DATA"
}]

apis.map((api) => {
    onlineLearning[api.name] = function (data) {
        return axios(url, {
            data: Object.assign({}, {operationType: api.operationType}, data),
        })
    }
})

export default onlineLearning