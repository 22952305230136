import {classesListService, schedulerListService} from '../../service/services/dutyTableSettingListService'
import {handleResultCode} from "../../service/service"
import {message} from "antd"

const START_LOADING_CALENDER_LIST = 'START_LOADING_CALENDER_LIST',
    FINISH_LOADING_CALENDER_LIST = 'FINISH_LOADING_CALENDER_LIST',
    START_LOADING_CLASSES_LIST = 'START_LOADING_CLASSES_LIST',
    FINISH_LOADING_CLASSES_LIST = 'FINISH_LOADING_CLASSES_LIST',
    START_LOADING_DUTY_OFFICER_LIST = 'START_LOADING_DUTY_OFFICER_LIST',
    FINISH_LOADING_DUTY_OFFICER_LIST = 'FINISH_LOADING_DUTY_OFFICER_LIST',
    START_SUBMIT = 'START_SUBMIT',
    FINISH_SUBMIT = 'FINISH_SUBMIT',
    START_LOADING_SCHEDULE_LIST = 'START_LOADING_SCHEDULE_LIST',
    FINISH_LOADING_SCHEDULE_LIST = 'FINISH_LOADING_SCHEDULE_LIST',
    START_DELETE_SCHEDULE = 'START_DELETE_SCHEDULE',
    FINISH_DELETE_SCHEDULE = 'FINISH_DELETE_SCHEDULE',
    START_LOADING_DAY_SCHEDULE_LIST='START_LOADING_DAY_SCHEDULE_LIST',
    FINISH_LOADING_DAY_SCHEDULE_LIST='FINISH_LOADING_DAY_SCHEDULE_LIST'

const initState = {
    calenderList: [],
    classesList: [],
    schedulerList: [],
    dayScheduleList: [],
}

//获取值班表日历接口数据
export function startGettingCalenderList() {
    return {
        type: START_LOADING_CALENDER_LIST
    }
}

export function finishGettingCalenderList(res) {
    return {
        type: FINISH_LOADING_CALENDER_LIST,
        dutyTableSettingList: res.dutyTableSettingList
    }
}

export function getCalenderList(params) {
    return dispatch => {
        dispatch(startGettingCalenderList())
        classesListService.query(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    let {pageIndex, pageSize, systemTaskDTOList, totalItemCount, totalPageCount} = res.data
                    dispatch(finishGettingCalenderList({pageIndex, pageSize, systemTaskDTOList, totalItemCount, totalPageCount}))
                }
            })
            .catch(()=> {
            })
    }
}


//添加班次时先查询班次列表
export function startGettingClassesList() {
    return {
        type: START_LOADING_CLASSES_LIST
    }
}

export function finishGettingClassesList(res) {
    return {
        type: FINISH_LOADING_CLASSES_LIST,
        classesList: res.classesList
    }
}

export function getClassesList() {
    return dispatch => {
        dispatch(startGettingClassesList())
        classesListService.query()
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    let {pageIndex, pageSize, classesList, totalItemCount, totalPageCount} = res.data
                    dispatch(finishGettingClassesList({pageIndex, pageSize, classesList, totalItemCount, totalPageCount}))
                }
            })
            .catch(()=> {
            })
    }
}



//添加班次时先查询值班人员列表
export function startGettingDutyOfficerList() {
    return {
        type: START_LOADING_DUTY_OFFICER_LIST
    }
}

export function finishGettingDutyOfficerList(res) {
    return {
        type: FINISH_LOADING_DUTY_OFFICER_LIST,
        schedulerList: res.scheduler? res.scheduler.userList : []
    }
}

export function getDutyOfficerList(params) {
    return dispatch => {
        dispatch(startGettingDutyOfficerList())
        schedulerListService.getSchedulerList(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    let {pageIndex, pageSize, scheduler, totalItemCount, totalPageCount} = res.data
                    dispatch(finishGettingDutyOfficerList({pageIndex, pageSize, scheduler, totalItemCount, totalPageCount}))
                }
            })
            .catch(()=> {
            })
    }
}


//获取一个月对班次列表（接口相同）
export function startGettingScheduleList() {
    return {
        type: START_LOADING_SCHEDULE_LIST
    }
}

export function finishGettingScheduleList(res) {
    return {
        type: FINISH_LOADING_SCHEDULE_LIST,
        scheduleList: res.schedulerList
    }
}

export function getScheduleList(params, cb) {
    return dispatch => {
        dispatch(startGettingScheduleList())
        schedulerListService.getScheduleList(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    let {pageIndex, pageSize, schedulerList, totalItemCount, totalPageCount} = res.data
                    cb(schedulerList)
                    dispatch(finishGettingScheduleList({pageIndex, pageSize, schedulerList, totalItemCount, totalPageCount}))
                }
            })
            .catch(()=> {
            })
    }
}

//获取每一天对班次列表，
export function startGettingDayScheduleList() {
    return {
        type: START_LOADING_DAY_SCHEDULE_LIST
    }
}

export function finishGettingDayScheduleList(res) {
    return {
        type: FINISH_LOADING_DAY_SCHEDULE_LIST,
        dayScheduleList: res.schedulerList
    }
}

export function getDayScheduleList(params) {
    return dispatch => {
        dispatch(startGettingDayScheduleList())
        schedulerListService.getScheduleList(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    let {pageIndex, pageSize, schedulerList, totalItemCount, totalPageCount} = res.data
                    dispatch(finishGettingDayScheduleList({pageIndex, pageSize, schedulerList, totalItemCount, totalPageCount}))
                }
            })
            .catch(()=> {
            })
    }
}




//添加班次提交

export function startGettingSubmit() {
    return {
        type: START_SUBMIT
    }
}

export function finishGettingSubmit() {
    return {
        type: FINISH_SUBMIT,
    }
}

export function handleSubmit(params, isEdit, schedulerInfoObj,  successCb) {
    return dispatch => {
        dispatch(startGettingSubmit())
        schedulerListService.handleSubmit(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    message.success(isEdit ? '更新班次成功' : '添加班次成功')
                    successCb()
                    dispatch(finishGettingSubmit())
                    dispatch(getDayScheduleList(schedulerInfoObj))
                }
            })
            .catch(()=> {
            })
    }
}



//删除班次提交

export function startDeleteSchedule() {
    return {
        type: START_DELETE_SCHEDULE
    }
}

export function finishDeleteSchedule() {
    return {
        type: FINISH_DELETE_SCHEDULE,
    }
}

export function deleteSchedule(params, {schedulerInfoObj}) {
    return dispatch => {
        dispatch(startDeleteSchedule())
        schedulerListService.deleteSchedule(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    message.success('删除班次成功')
                    dispatch(getDayScheduleList(schedulerInfoObj))
                }
            })
            .catch(()=> {
            })
    }
}




export function dutyTableSettingListReducer(state = initState, action) {
    switch (action.type) {
        case START_LOADING_CALENDER_LIST:
            return {
                ...state,
                dutyTableSettingListIsFetching: true
            }
        case FINISH_LOADING_CALENDER_LIST:
            return {
                ...state,
                dutyTableSettingList: action.dutyTableSettingList,
            }
        case START_LOADING_CLASSES_LIST:
            return {
                ...state,
                classesListIsFetching: true
            }
        case FINISH_LOADING_CLASSES_LIST:
            return {
                ...state,
                classesListIsFetching: false,
                classesList: action.classesList,
            }
        case START_LOADING_DUTY_OFFICER_LIST:
            return {
                ...state,
                schedulerListIsFetching: true
            }
        case FINISH_LOADING_DUTY_OFFICER_LIST:
            return {
                ...state,
                schedulerListIsFetching: false,
                schedulerList: action.schedulerList,
            }
        case START_SUBMIT:
            return {
                ...state,
                submitIsFetching: true
            }
        case FINISH_SUBMIT:
            return {
                ...state,
                submitIsFetching: false,
            }
        case START_LOADING_SCHEDULE_LIST:
            return {
                ...state,
                scheduleListIsFetching: true
            }
        case FINISH_LOADING_SCHEDULE_LIST:
            return {
                ...state,
                scheduleListIsFetching: false,
                scheduleList: action.scheduleList,
            }

        case START_LOADING_DAY_SCHEDULE_LIST:
            return {
                scheduleListIsFetching: true
            }
        case FINISH_LOADING_DAY_SCHEDULE_LIST:
            return {
                scheduleListIsFetching: false,
                dayScheduleList: action.dayScheduleList,
            }
        case START_DELETE_SCHEDULE:
            return {
                ...state,
                scheduleListIsFetching: true
            }
        case FINISH_DELETE_SCHEDULE:
            return {
                ...state,
                scheduleListIsFetching: false,
            }

        default:
            return state
    }
}