import mainTask from '../../service/services/mainTask'
import {handleResultCode} from "../../service/service"

const HANDLE_ERROR = 'HANDLE_ERROR',
  START_LOADING_VOICE_ANNOTATE_TYPE = 'START_LOADING_VOICE_ANNOTATE_TYPE',
  FINISH_LOADING_VOICE_ANNOTATE_TYPE = 'FINISH_LOADING_VOICE_ANNOTATE_TYPE'

const initState = {
  audioAnnotationTypeIsFetching: false,
  audioAnnotationType: []
}

function startGettingVoiceAnnotationType() {
  return {
    type: START_LOADING_VOICE_ANNOTATE_TYPE
  }
}

function finishGettingVoiceAnnotationType(mConfigValues) {
  return {
    type: FINISH_LOADING_VOICE_ANNOTATE_TYPE,
    audioAnnotationType: JSON.parse(mConfigValues[0].value)
  }
}

function handleError() {
  return {
    type: HANDLE_ERROR
  }
}

export function getAudioAnnotationType() {
  return dispatch => {
    dispatch(startGettingVoiceAnnotationType())
    mainTask.getAudioAnnotationType()
      .then((response) => {
        return handleResultCode(response)
      })
      .then((res) => {
        if(res.success) {
          dispatch(finishGettingVoiceAnnotationType(res.data.mConfigValues))
        } else {
          dispatch(handleError())
        }
      })
  }
}

export function mConfigs(state = initState, action) {
  switch (action.type) {
    case START_LOADING_VOICE_ANNOTATE_TYPE:
      return {
        ...state,
        audioAnnotationTypeIsFetching: true
      }
    case FINISH_LOADING_VOICE_ANNOTATE_TYPE:
      return {
        ...state,
        audioAnnotationTypeIsFetching: false,
        audioAnnotationType: action.audioAnnotationType
      }
    case HANDLE_ERROR:
      return {
        ...state,
        audioAnnotationTypeIsFetching: false
      }
    default:
      return state
  }
}