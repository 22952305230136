import 'babel-polyfill'
import React from 'react'
import ReactDom from 'react-dom'
import {Provider} from 'react-redux'
import App from './router/router'
import configStore from './redux/configStore'

const store = configStore()

ReactDom.render(
    <Provider store={store}>
        {App()}
    </Provider>
    , document.getElementById('app')
)