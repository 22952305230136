import React, {Component} from 'react'
import {connect} from 'react-redux'
import propTypes from 'prop-types'
import Layout from 'antd/lib/layout'
import 'antd/dist/antd.less'
import {getUserProfile} from '../redux/reducers/userProfile'
import PageLoading from './PageLoading/index'
import '../styles/global.less'
import Nav from './Nav/index'
import {LocaleProvider} from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import {withRouter} from "react-router-dom"

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedKeys: '',
            collapsed: false
        }
        this.onCollapse = this.onCollapse.bind(this)
    }

    componentDidMount() {
        if(!this.props.user) {
            this.props.getUserProfile()
        }
    }

    onCollapse(collapsed) {
        this.setState({ collapsed })
    }

    getChildContext() {
        return {
            selectedKeys: this.state.selectedKeys,
            setKeys: (keys) => {
                this.setState({
                    selectedKeys: keys
                })
            }
        }
    }

    render() {
        const {user, isFetching, accessInfos} = this.props
        if(user&&!isFetching) {
            return(
                <LocaleProvider locale={zhCN}>
                    <Layout style={{ minHeight: '100vh' }}>
                        <Nav accessInfos={accessInfos} userDetailInfo={user} collapsed={this.state.collapsed} onCollapse={this.onCollapse}/>
                        <Layout className={this.state.collapsed?'global-layout-collapsed':'global-layout'}>
                            {this.props.children}
                        </Layout>
                    </Layout>
                </LocaleProvider>)
        } else {
            return (<PageLoading/>)
        }
    }
}

App.propTypes = {
    getUserProfile: propTypes.func,
    isFetching: propTypes.bool,
    user: propTypes.object,
    children: propTypes.any,
    accessInfos: propTypes.array
}

App.childContextTypes = {
    selectedKeys: propTypes.string,
    setKeys: propTypes.func,
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.userProfile.isFetching,
        user: state.userProfile.user,
        accessInfos: state.userProfile.accessInfos
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfile: () => {
            dispatch(getUserProfile())
        }
    }
}

export default withRouter(connect( mapStateToProps, mapDispatchToProps)(App))