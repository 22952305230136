import userAuth from '../../service/services/userAuth'
import onlineLearningService from '../../service/services/onlineLearning'
import {handleResultCode} from "../../service/service"

const START_LOADING_TOKEN = 'START_GETTING_TOKEN',
    FINISH_LOADING_TOKEN = 'FINISH_GETTING_TOKEN'

const initState = {
    isLoggingIn: false
}

function startGettingToken() {
    return {
        type: START_LOADING_TOKEN
    }
}

export function getTokenByKey(authKey, cb) {
    return dispatch => {
        dispatch(startGettingToken())
        userAuth.loginByKey({authKey:authKey})
            .then((response)=>{
                return handleResultCode(response)
            })
            .then(res => {
                if(res.success) {
                    window.localStorage.setItem("leyan_data_annotation_platform_auth", res.data.token)
                    dispatch(finishGettingToken(res.data.token))
                    cb()
                }
            })
            .catch(err => {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

function finishGettingToken(token) {
    return {
        type: FINISH_LOADING_TOKEN,
        token: token
    }
}

export function getItem(authKey, taskId, cb) {
    onlineLearningService.getItem({
        authKey,
        taskId
    })
        .then((response)=>{
            return handleResultCode(response)
        })
        .then(res => {
            if(res.success) {
                cb(res.data.manualDataItem, res.data.stage, res.data.orderProcess)
            }
        })
        .catch(err => {
            console.log("---Oops, error!---")
            console.log(err)
        })
}

export function onlineLearningReducer(state = initState, action) {
    switch (action.type) {
        case START_LOADING_TOKEN:
            return {
                ...state,
                isLoggingIn: true
            }
        case FINISH_LOADING_TOKEN:
            return {
                ...state,
                isLoggingIn: false,
                token: action.token
            }
        default:
            return state
    }
}

