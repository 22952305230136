import books from '../../service/services/booksManagement'
import { handleResultCode } from "../../service/service"
import {Modal} from 'antd'
import { push } from "react-router-redux"

const START_GET_SECTION_BLOCK = 'START_GET_SECTION_BLOCK',
    FINISH_GET_SECTION_BLOCK = 'FINISH_GET_SECTION_BLOCK',
    START_GET_SYSTEM_DICT = 'START_GET_SYSTEM_DICT',
    FINISH_GET_SYSTEM_DICT = 'FINISH_GET_SYSTEM_DICT',
    START_CREATE_BOOKS = 'START_CREATE_BOOKS',
    FINISH_CREATE_BOOKS = 'FINISH_CREATE_BOOKS',
    FAIL_CREATE_BOOKS = 'FAIL_CREATE_BOOKS',
    START_GET_QUESTION = 'START_GET_QUESTION',
    FINISH_GET_QUESTION = 'FINISH_GET_QUESTION'

const initState = {
    sectionBlocks: [],
    dictTreeDtos: [],
    isLoading: null,
    questionConfigs: []
}

function startGetSectionBlock() {
    return {
        type: START_GET_SECTION_BLOCK
    }
}

function finishGetSectionBlock(res) {
    return {
        type: FINISH_GET_SECTION_BLOCK,
        sectionBlocks: res
    }
}

export function getSectionBlock(params) {
    return dispatch => {
        dispatch(startGetSectionBlock())
        books.getSectionBlock(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishGetSectionBlock(res.data.sectionBlocks))
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

function startGetSystemDict() {
    return {
        type: START_GET_SYSTEM_DICT
    }
}

function finishGetSystemDict(res) {
    return {
        type: FINISH_GET_SYSTEM_DICT,
        dictTreeDtos: res
    }
}

export function getSystemDict(params) {
    return dispatch => {
        dispatch(startGetSystemDict())
        books.getSystemDict(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishGetSystemDict(res.data.dictTreeDtos[0].chlidDicts))
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

function startCreateBooks() {
    return {
        type: START_CREATE_BOOKS
    }
}

function finishCreateBooks() {
    return {
        type: FINISH_CREATE_BOOKS
    }
}

function failCreateBooks() {
    return {
        type: FAIL_CREATE_BOOKS
    }
}

export function createBooks(params, isReview) {
    return dispatch => {
        dispatch(startCreateBooks())
        books.createBooks(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishCreateBooks())
                    if (isReview) {
                        //保存并提交
                        books.textBookStatus({textBookStatus: 'REVIEW', textBookId: res.data.textBookId})
                    }
                    Modal.success({
                        title: '新增课文成功！',
                        onOk() {
                            dispatch(push('/resource/bookManagement'))
                        }
                    })
                } else {
                    dispatch(failCreateBooks())
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}
export function updateBooks(params, isReview) {
    return dispatch => {
        dispatch(startCreateBooks())
        books.updateBooks(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishCreateBooks())
                    if (isReview) {
                        //保存并提交
                        books.textBookStatus({textBookStatus: 'REVIEW', textBookId: res.data.textBookId})
                    }
                    Modal.success({
                        title: '修改课文成功！',
                        onOk() {
                            dispatch(push('/resource/bookManagement'))
                        }
                    })
                } else {
                    dispatch(failCreateBooks())
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

function startGetQuestion() {
    return {
        type: START_GET_QUESTION
    }
}

function finishGetQuestion(res) {
    return {
        type: FINISH_GET_QUESTION,
        questionConfigs: res
    }
}

export function getQuestion(params) {
    return dispatch => {
        dispatch(startGetQuestion())
        books.getQuestion(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishGetQuestion(res.data.questionConfigs))
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

export function booksManagement(state = initState, action) {
    switch (action.type) {
        case START_GET_SECTION_BLOCK:
            return {
                ...state
            }
        case FINISH_GET_SECTION_BLOCK:
            return {
                ...state,
                sectionBlocks: action.sectionBlocks
            }
        case START_GET_SYSTEM_DICT:
            return {
                ...state
            }
        case FINISH_GET_SYSTEM_DICT:
            return {
                ...state,
                dictTreeDtos: action.dictTreeDtos
            }
        case START_CREATE_BOOKS:
            return {
                ...state,
                isLoading: true
            }
        case FINISH_CREATE_BOOKS:
            return {
                ...state,
                isLoading: false
            }
        case FAIL_CREATE_BOOKS:
            return {
                ...state,
                isLoading: false
            }
        case START_GET_QUESTION:
            return {
                ...state
            }
        case FINISH_GET_QUESTION:
            return {
                ...state,
                questionConfigs: action.questionConfigs
            }
        default:
            return state
    }
}