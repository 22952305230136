import scenario from '../../service/services/scenario'
import {handleResultCode} from "../../service/service"
import {Modal} from 'antd'
import {push} from "react-router-redux"

const START_LOADING_SCENARIO_LIST = 'START_LOADING_SCENARIO_LIST',
    FINISH_LOADING_SCENARIO_LIST = 'FINISH_LOADING_SCENARIO_LIST',
    START_LOADING_INDUSTRY_LIST = 'START_LOADING_INDUSTRY_LIST',
    FINISH_LOADING_INDUSTRY_LIST = 'FINISH_LOADING_INDUSTRY_LIST',
    START_DELETING_SCENARIO = 'START_DELETING_SCENARIO',
    FINISH_DELETING_SCENARIO = 'FINISH_DELETING_SCENARIO',
    START_ADDING_SCENARIO_LIST = 'START_ADDING_SCENARIO_LIST',
    FINISH_ADDING_SCENARIO_LIST = 'FINISH_ADDING_SCENARIO_LIST'

const initState = {
    scenarioIsFetching: false,
    industryListIsFetching: null,
    scenarios: {
        pageIndex: 1,
        pageSize: 9999,
        totalItemCount: 0,
        totalPageCount: 1,
        scenarioList:[]
    },
    industryList: []
}

export function startGettingScenarios() {
    return {
        type: START_LOADING_SCENARIO_LIST
    }
}

export function finishGettingScenarios(res) {
    return {
        type: FINISH_LOADING_SCENARIO_LIST,
        scenarios: res
    }
}

export function getScenarios(params) {
    return dispatch => {
        dispatch(startGettingScenarios())
        scenario.query(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    let {pageIndex, pageSize, scenario, scenarioList, totalItemCount, totalPageCount} = res.data
                    dispatch(finishGettingScenarios({pageIndex, pageSize, scenario, scenarioList, totalItemCount, totalPageCount}))
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

function startGettingIndustryList() {
    return {
        type: START_LOADING_INDUSTRY_LIST
    }
}

function finishGettingIndustryList(res) {
    return {
        type: FINISH_LOADING_INDUSTRY_LIST,
        industryList: res
    }
}

export function updateScenario(params) {
    return dispatch => {
        dispatch(startAddingScenarios())
        scenario.update(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    dispatch(finishAddingScenarios())
                    Modal.success({
                        title: '更新场景成功！',
                        onOk() {
                            dispatch(push('/scenarios'))
                        }
                    })
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

export function getIndustryList() {
    return dispatch => {
        dispatch(startGettingIndustryList())
        scenario.getBasicData()
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    dispatch(finishGettingIndustryList(res.data.industries))
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

export function deleteScenario(params) {
    return dispatch => {
        dispatch(startDeleteScenario())
        scenario.delete(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                dispatch(finishDeleteScenario())
                if(res.success) {
                    dispatch(getScenarios())
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

function startDeleteScenario() {
    return {
        type: START_DELETING_SCENARIO
    }
}

function finishDeleteScenario() {
    return {
        type: FINISH_DELETING_SCENARIO
    }
}

function startAddingScenarios() {
    return {
        type: START_ADDING_SCENARIO_LIST
    }
}

function finishAddingScenarios() {
    return {
        type: FINISH_ADDING_SCENARIO_LIST
    }
}

export function createScenario(params) {
    return dispatch => {
        dispatch(startAddingScenarios())
        scenario.create(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    dispatch(finishAddingScenarios())
                    Modal.success({
                        title: '新增场景成功！',
                        onOk() {
                            dispatch(push('/scenarios'))
                        }
                    })
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

export function scenarios(state = initState, action) {
    switch (action.type) {
        case START_LOADING_SCENARIO_LIST:
        case START_DELETING_SCENARIO:
        case START_ADDING_SCENARIO_LIST:
            return {
                ...state,
                scenarioIsFetching: true
            }
        case FINISH_LOADING_SCENARIO_LIST:
            return {
                ...state,
                scenarioIsFetching: false,
                scenarios: action.scenarios
            }
        case START_LOADING_INDUSTRY_LIST:
            return {
                ...state,
                industryListIsFetching: true
            }
        case FINISH_LOADING_INDUSTRY_LIST:
            return {
                ...state,
                industryListIsFetching: false,
                industryList: action.industryList
            }
        case FINISH_DELETING_SCENARIO:
        case FINISH_ADDING_SCENARIO_LIST:
            return {
                ...state,
                scenarioIsFetching: false
            }
        default:
            return state
    }
}