import {handleResultCode} from "../../service/service"
import users from "../../service/services/users"

const HANDLE_ERROR = 'HANDLE_ERROR',
    START_LOADING = 'START_LOADING',
    FINISH_LOADING = 'FINISH_LOADING'

const initState = {
    userListIsFetching: false,
    userList: []
}

function startGettingUserList() {
    return {
        type: START_LOADING
    }
}

function finishGettingUserList(res) {
    return {
        type: FINISH_LOADING,
        userList: res
    }
}

function handleError() {
    return {
        type: HANDLE_ERROR
    }
}

export function getUserList() {
    return dispatch => {
        dispatch(startGettingUserList())
        users.query({
            pageSize: 9999,
            page: 1
        })
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishGettingUserList(res.data.userDTOList))
                } else {
                    dispatch(handleError())
                }
            })
    }
}

export function userList(state = initState, action) {
    switch (action.type) {
        case START_LOADING:
            return {
                ...state,
                userListIsFetching: true
            }
        case FINISH_LOADING:
            return {
                ...state,
                userListIsFetching: false,
                userList: action.userList
            }
        case HANDLE_ERROR:
            return {
                ...state,
                userListIsFetching: false
            }
        default:
            return state
    }
}