import books from '../../service/services/booksManagement'
import {handleResultCode} from "../../service/service"
import {Modal} from 'antd'
import {push} from "react-router-redux"

const START_LOADING_BOOK_LIST = 'START_LOADING_BOOK_LIST',
    FINISH_LOADING_BOOK_LIST = 'FINISH_LOADING_BOOK_LIST',
    START_TEXT_BOOKS_TATUS = 'START_TEXT_BOOKS_TATUS',
    FINISH_TEXT_BOOKS_TATUS = 'FINISH_TEXT_BOOKS_TATUS',
    FAIL_TEXT_BOOKS_TATUS = 'FAIL_TEXT_BOOKS_TATUS',
    START_TEXT_BOOKS_DELETE = 'START_TEXT_BOOKS_DELETE',
    FINISH_TEXT_BOOKS_DELETE = 'FINISH_TEXT_BOOKS_DELETE',
    FAIL_TEXT_BOOKS_DELETE = 'FAIL_TEXT_BOOKS_DELETE',
    START_BULK_RELEASE = 'START_BULK_RELEASE',
    FINISH_BULK_RELEASE = 'FINISH_BULK_RELEASE',
    FAIL_BULK_RELEASE = 'FAIL_BULK_RELEASE'

const initState = {
    bookListIsFetching: false,
    pageIndex: 1,
    pageSize: 10,
    totalPageCount: 1,
    totalItemCount: 1,
    bookList: [],
    countReview: null,
    isLoading: false,
    pageState: {},
    isReleaseLoading: false
}

export function startGettingBookList() {
    return {
        type: START_LOADING_BOOK_LIST
    }
}

export function finishGettingBookList(res) {
    return {
        type: FINISH_LOADING_BOOK_LIST,
        pageIndex: res.pageIndex,
        pageSize: res.pageSize,
        totalPageCount: res.totalPageCount,
        totalItemCount: res.totalItemCount,
        bookList: res.textBookDTOS,
        countReview: res.countReview,
        pageState: res.params
    }
}

export function getBookList(params,cb) {
    return dispatch => {
        dispatch(startGettingBookList())
        books.query(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if (res.success) {
                    let {pageIndex, pageSize, textBookDTOS, totalItemCount, totalPageCount, countReview} = res.data
                    dispatch(finishGettingBookList({pageIndex, pageSize, textBookDTOS, totalItemCount, totalPageCount, countReview, params}))
                    console.log(res.data,'res.data')
                    if ((pageIndex>1)&&textBookDTOS&&textBookDTOS.length===0) {
                        cb(pageIndex-1)
                    }
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

function startTextBookStatus() {
    return {
        type: START_TEXT_BOOKS_TATUS
    }
}

function finishTextBookStatus() {
    return {
        type: FINISH_TEXT_BOOKS_TATUS
    }
}

function failTextBookStatus() {
    return {
        type: FAIL_TEXT_BOOKS_TATUS
    }
}

export function textBookStatus(params) {
    return dispatch => {
        dispatch(startTextBookStatus())
        books.textBookStatus(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishTextBookStatus())
                    Modal.success({
                        title: '操作成功！',
                        onOk() {
                            dispatch(push('/resource/bookManagement'))
                        }
                    })
                } else {
                    dispatch(failTextBookStatus())
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

function startDeleteTextBookDetaile() {
    return {
        type: START_TEXT_BOOKS_DELETE
    }
}

function finishDeleteTextBookDetaile() {
    return {
        type: FINISH_TEXT_BOOKS_DELETE
    }
}

function failDeleteTextBookDetaile() {
    return {
        type: FAIL_TEXT_BOOKS_DELETE
    }
}

export function deleteTextBookDetaile(params) {
    return dispatch => {
        dispatch(startDeleteTextBookDetaile())
        books.deleteTextBookDetaile(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishDeleteTextBookDetaile())
                    Modal.success({
                        title: '操作成功！',
                        onOk() {
                            dispatch(push('/resource/bookManagement'))
                        }
                    })
                } else {
                    dispatch(failDeleteTextBookDetaile())
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

function startBulkRelease() {
    return {
        type: START_BULK_RELEASE
    }
}

function finishBulkRelease() {
    return {
        type: FINISH_BULK_RELEASE
    }
}

function failBulkRelease() {
    return {
        type: FAIL_BULK_RELEASE
    }
}

export function bulkRelease(params) {
    return dispatch => {
        dispatch(startBulkRelease())
        books.bulkRelease(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishBulkRelease())
                    Modal.success({
                        title: '操作成功！',
                        onOk() {
                            dispatch(push('/resource/bookManagement'))
                        }
                    })
                } else {
                    dispatch(failBulkRelease())
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

export function booksList(state = initState, action) {
    switch (action.type) {
        case START_LOADING_BOOK_LIST:
            return {
                ...state,
                bookListIsFetching: true
            }
        case FINISH_LOADING_BOOK_LIST:
            return {
                ...state,
                bookListIsFetching: false,
                pageIndex: action.pageIndex,
                pageSize: action.pageSize,
                bookList: action.bookList,
                totalItemCount: action.totalItemCount,
                totalPageCount: action.totalPageCount,
                countReview: action.countReview,
                pageState: action.pageState
            }
        case START_TEXT_BOOKS_TATUS:
            return {
                ...state,
                isLoading: true
            }
        case FINISH_TEXT_BOOKS_TATUS:
            return {
                ...state,
                isLoading: false
            }
        case FAIL_TEXT_BOOKS_TATUS:
            return {
                ...state,
                isLoading: false
            }
        case START_TEXT_BOOKS_DELETE:
            return {
                ...state
            }
        case FINISH_TEXT_BOOKS_DELETE:
            return {
                ...state
            }
        case FAIL_TEXT_BOOKS_DELETE:
            return {
                ...state
            }
        case START_BULK_RELEASE:
            return {
                ...state,
                isReleaseLoading: true
            }
        case FINISH_BULK_RELEASE:
            return {
                ...state,
                isReleaseLoading: false
            }
        case FAIL_BULK_RELEASE:
            return {
                ...state,
                isReleaseLoading: false
            }
        default:
            return state
    }
}