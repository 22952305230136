import axios from '../service'

const url = '/xbizapi/226/mytask'

var myTask = {}

const apis = [{
    name: "query",
    operationType: "QUERY_MYTASK"
}]

apis.map((api) => {
    myTask[api.name] = function (data) {
        return axios(url, {
            data: Object.assign({}, {operationType: api.operationType}, data)
        })
    }
})

export default myTask