import userAuth from '../../service/services/userAuth'
import {handleResultCode} from "../../service/service"
import {push} from 'react-router-redux'
const START_LOADING_PROFILE = 'START_LOADING_PROFILE',
    FINISH_LOADING_PROFILE = 'FINISH_LOADING_PROFILE',
    START_LOADING_TOKEN = 'START_GETTING_TOKEN',
    FINISH_LOADING_TOKEN = 'FINISH_GETTING_TOKEN'

const initState = {
    isFetching: false,
    token: window.localStorage.getItem("leyan_data_annotation_platform_auth"),
    user: null,
    accessInfos: []
}

function startGettingUserProfile() {
    return {
        type: START_LOADING_PROFILE
    }
}

export function getUserProfile() {
    if (window.localStorage.getItem("leyan_data_annotation_platform_auth")) {
        return dispatch => {
            dispatch(startGettingUserProfile())
            userAuth.getInfo()
                .then((response)=>{
                    return handleResultCode(response)
                })
                .then(res => {
                    if (res.success) {
                        if (res.data && res.data.accessInfos && res.data.accessInfos.length > 0) {
                            const authFns = res.data.accessInfos
                            let newList = []
                            authFns.map(auth => {
                                if (newList.length > 0) {
                                    auth.functionInfos.map(item => {
                                        if (item.checked === true) {
                                            newList.find(e => e.id === item.id).checked = true
                                        }
                                    })
                                } else {
                                    newList = auth.functionInfos
                                }
                            })
                            localStorage.setItem('leyan_data_annotation_platform_auth_fns', JSON.stringify(newList))
                        }
                        dispatch(finishGettingUserProfile({user:res.data.userDetailInfo,accessInfos: res.data.accessInfos}))
                    }
                })
                .catch(err => {
                    console.log("---Oops, error!---")
                    console.log(err)
                })
        }
    } else {
        return dispatch => {
            dispatch(push('/login'))
        }
    }
}

function finishGettingUserProfile(user) {
    return {
        type: FINISH_LOADING_PROFILE,
        user: user.user,
        accessInfos: user.accessInfos
    }
}

function startGettingToken() {
    return {
        type: START_LOADING_TOKEN
    }
}

export function getToken(authInfo) {
    return dispatch => {
        dispatch(startGettingToken())
        userAuth.login({authInfo:authInfo})
            .then((response)=>{
                return handleResultCode(response)
            })
            .then(res => {
                if(res.success) {
                    window.localStorage.setItem("leyan_data_annotation_platform_auth", res.data.token)
                    dispatch(finishGettingToken(res.data.token))
                    dispatch(push('/myTasks'))
                }
            })
            .catch(err => {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

function finishGettingToken(token) {
    return {
        type: FINISH_LOADING_TOKEN,
        token: token
    }
}

export function userProfile(state = initState, action) {
    switch (action.type) {
        case START_LOADING_PROFILE:
            return {
                ...state,
                isFetching: true
            }
        case FINISH_LOADING_PROFILE:
            return {
                ...state,
                isFetching: false,
                user: action.user,
                accessInfos: action.accessInfos
            }
        case START_LOADING_TOKEN:
            return {
                ...state,
                isFetching: true
            }
        case FINISH_LOADING_TOKEN:
            return {
                ...state,
                isFetching: false,
                token: action.token
            }
        default:
            return state
    }
}

