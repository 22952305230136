import reduxLogger from 'redux-logger'
import reduxThunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducer'
import {routerMiddleware} from 'react-router-redux'
import history from '../router/history'
const historyMiddleware = routerMiddleware(history)

export default function configureStore() {
    if(process.env.NODE_ENV=='dev') {
        return createStore(
            rootReducer,
            applyMiddleware(reduxThunk, reduxLogger, historyMiddleware)
        )
    } else {
        return createStore(
            rootReducer,
            applyMiddleware(reduxThunk, historyMiddleware)
        )
    }
}