import mainTask from '../../service/services/mainTask'
import {handleResultCode} from "../../service/service"
import {Modal} from 'antd'
import {push} from "react-router-redux"

const START_LOADING_TASK_LIST = 'START_LOADING_TASK_LIST',
    FINISH_LOADING_TASK_LIST = 'FINISH_LOADING_TASK_LIST',
    HANDLE_ERROR = 'HANDLE_ERROR',
    START_CREATING_TASK = 'START_CREATING_TASK',
    FINISH_CREATING_TASK = 'FINISH_CREATING_TASK'
const START_CHANGE_ENABLED_STATUS = 'START_CHANGE_ENABLED_STATUS'
const FINISH_CHANGE_ENABLED_STATUS = 'FINISH_CHANGE_ENABLED_STATUS'
const FAIL_CHANGE_ENABLED_STATUS = 'FAIL_CHANGE_ENABLED_STATUS'
const initState = {
    taskListIsFetching: false,
    taskIsCreating: false,
    pageIndex: 1,
    totalItemCount: 1,
    totalPageCount: 1,
    mainTaskDTO: null,
    mainTaskDTOList:[]
}

// 启用/禁用任务
function startChangeEnabledStatus() {
    return {
        type: START_CHANGE_ENABLED_STATUS
    }
}

function finishChangeEnabledStatus() {
    return {
        type: FINISH_CHANGE_ENABLED_STATUS
    }
}

function failChangeEnabledStatus() {
    return {
        type: FAIL_CHANGE_ENABLED_STATUS
    }
}

export function changeEnabledStatus(params, successCb, failCb) {
    return dispatch => {
        dispatch(startChangeEnabledStatus())
        mainTask.changeEnabledStatus(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishChangeEnabledStatus())
                    successCb()
                } else {
                    dispatch(failChangeEnabledStatus())
                    failCb()
                }
            })
            .catch(() => {
                dispatch(failChangeEnabledStatus())
                failCb()
            })
    }
}

function startGettingTaskList() {
    return {
        type: START_LOADING_TASK_LIST
    }
}

function finishGettingTaskList(res) {
    return {
        type: FINISH_LOADING_TASK_LIST,
        mainTasks: res
    }
}

function handleError() {
    return {
        type: HANDLE_ERROR
    }
}

export function getTaskList(params) {
    return dispatch => {
        dispatch(startGettingTaskList())
        mainTask.query(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if(res.success) {
                    let {pageIndex, pageSize, mainTaskDTO, mainTaskDTOList, totalItemCount, totalPageCount} = res.data
                    dispatch(finishGettingTaskList({pageIndex, pageSize, mainTaskDTO, mainTaskDTOList, totalItemCount, totalPageCount}))
                } else {
                    dispatch(handleError())
                }
            })
    }
}

export function createTask(params) {
    return dispatch => {
        dispatch(startCreatingTaskList())
        mainTask.create(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishCreatingTaskList())
                    Modal.success({
                        title: '新增任务成功！',
                        onOk() {
                            dispatch(push('/scenarios/'+params.mainTaskDTO.scenarioId))
                        }
                    })
                } else {
                    dispatch(handleError())
                }
            })
    }
}

function startCreatingTaskList() {
    return {
        type: START_CREATING_TASK
    }
}

function finishCreatingTaskList() {
    return {
        type: FINISH_CREATING_TASK
    }
}

export function tasks(state = initState, action) {
    switch (action.type) {
        case START_LOADING_TASK_LIST:
            return {
                ...state,
                taskListIsFetching: true
            }
        case FINISH_LOADING_TASK_LIST:
            return {
                ...state,
                taskListIsFetching: false,
                mainTaskDTO: action.mainTasks.mainTaskDTO,
                mainTaskDTOList: action.mainTasks.mainTaskDTOList,
                pageIndex: action.mainTasks.pageIndex,
                totalItemCount: action.mainTasks.totalItemCount,
                totalPageCount: action.mainTasks.totalPageCount
            }
        case START_CREATING_TASK:
            return {
                ...state,
                taskIsCreating: true
            }
        case FINISH_CREATING_TASK:
            return {
                ...state,
                taskIsCreating: false
            }
        case HANDLE_ERROR:
            return {
                ...state,
                taskListIsFetching: false,
                taskIsCreating: false
            }
        case START_CHANGE_ENABLED_STATUS:
            return {
                ...state,
            }
        case FINISH_CHANGE_ENABLED_STATUS:
            return {
                ...state,
            }
        case FAIL_CHANGE_ENABLED_STATUS:
            return {
                ...state,
            }
        default:
            return state
    }
}
