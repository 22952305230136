import { combineReducers } from 'redux'
import {routerReducer} from 'react-router-redux'
import {userProfile} from './reducers/userProfile'
import {scenarios} from './reducers/scenarios'
import {tasks} from './reducers/tasks'
import {taskDetail} from './reducers/taskDetail'
import {taskUpdate} from './reducers/taskUpdate'
import {myTaskList} from './reducers/myTaskList'
import {datasets} from './reducers/datasets'
import {flowDefine} from './reducers/flowDefine'
import {userList} from './reducers/userList'
import {annotateTask} from './reducers/annotateTask'
import {mConfigs} from './reducers/mconfigs'
import {scheduleListReducer} from './reducers/scheduleList'
import {timeSettingListReducer} from './reducers/timeSettingList'
import {dutyTableSettingListReducer} from './reducers/dutyTableSettingList'
import {onlineLearningReducer} from './reducers/onlineLearning'
import {statistics} from './reducers/statistics'
import {dataSetManagement} from './reducers/dataSetManagement'
import {roleManage} from './reducers/roleManage'
import {accountManage} from './reducers/accountManage'
import {booksManagement} from './reducers/booksManagement'
import {booksList} from './reducers/booksList'
const rootReducer = combineReducers({
    routerReducer,
    userProfile,
    scenarios,
    tasks,
    taskDetail,
    taskUpdate,
    myTaskList,
    datasets,
    flowDefine,
    userList,
    annotateTask,
    mConfigs,
    scheduleListReducer,
    timeSettingListReducer,
    dutyTableSettingListReducer,
    onlineLearningReducer,
    statistics,
    dataSetManagement,
    roleManage,
    accountManage,
    booksManagement,
    booksList
})
export default rootReducer
