const rootRoleName = [
    {
        name: '系统管理员',
        code: 'admin'
    },
    {
        name: '领域管理员',
        code: 'field_admin'
    },
    {
        name: '场景管理员',
        code: 'scene_admin'
    },
    {
        name: '场景小组长',
        code: 'scene_Leader'
    },
    {
        name: '组员',
        code: 'maker'
    },
]
const SCENE_TASK = 'SCENE_TASK'
const CREATE_SCENE = 'CREATE_SCENE'
const DELETE_SCENE = 'DELETE_SCENE'
const CREATE_TASK = 'CREATE_TASK'
const MARK_TASK = 'MARK_TASK'
const REVIEW_TASK = 'REVIEW_TASK'
const QUALITY_TASK = 'QUALITY_TASK'
const EXPORT = 'EXPORT'
const MY_TASK = 'MY_TASK'
const DATA_SET_MANAGEMENT = 'DATA_SET_MANAGEMENT'
const DATA_STATISTICS = 'DATA_STATISTICS'
const USER_MANAGEMENT = 'USER_MANAGEMENT'
const DUTY_TABLE = 'DUTY_TABLE'
const BOOK = 'BOOK'
const BOOK_REVIEW = 'BOOK_REVIEW'
const BOOK_EDIT = 'BOOK_EDIT'
const BOOK_RELEASE = 'BOOK_RELEASE'
const EDIT_SCENE = 'EDIT_SCENE'
const MAINTASK_ENABLED = 'MAINTASK_ENABLED'

const SCENE_TASK_SUB = [
    'SCENE_TASK', 'CREATE_SCENE', 'CREATE_TASK', 'MARK_TASK', 'REVIEW_TASK',
    'QUALITY_TASK', 'EXPORT', 'DELETE_SCENE', 'EDIT_SCENE'
]
const BOOK_SUB = ['BOOK', 'BOOK_REVIEW', 'BOOK_EDIT', 'BOOK_RELEASE']

function getAuthFns() {
    const authFns = localStorage.getItem('leyan_data_annotation_platform_auth_fns')
    return authFns ? JSON.parse(authFns) : []
}

// 通过权限判断是否显示页面或者按钮
function isShowViewWithAuth(functionCode) {
    if (Array.isArray(functionCode)) {
        const fn = getAuthFns().find(item => {
            return functionCode.find(f => item.functionCode === f && item.checked) ? true : false
        })
        return fn && fn.checked
    } else {
        const fn = getAuthFns().find(item => item.functionCode === functionCode)
        return fn && fn.checked
    }

}

export {
    SCENE_TASK,
    CREATE_SCENE,
    DELETE_SCENE,
    CREATE_TASK,
    MARK_TASK,
    REVIEW_TASK,
    QUALITY_TASK,
    EXPORT,
    MY_TASK,
    DATA_SET_MANAGEMENT,
    DATA_STATISTICS,
    USER_MANAGEMENT,
    DUTY_TABLE,
    rootRoleName,
    getAuthFns,
    isShowViewWithAuth,
    BOOK,
    BOOK_SUB,
    BOOK_REVIEW,
    BOOK_EDIT,
    BOOK_RELEASE,
    EDIT_SCENE,
    SCENE_TASK_SUB,
    MAINTASK_ENABLED
}
