import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import history from './history'
import { ConnectedRouter } from "react-router-redux"
import Bundle from './bundle'
import Layout from '../components/app.js'
import Home from 'bundle-loader?lazy&name=home!../pages/home/index.js'
import Scenarios from 'bundle-loader?lazy&name=scenarios!../pages/scenarios/index.js'
import CreateScenario from 'bundle-loader?lazy&name=createScenario!../pages/createScenario/index.js'
import EditScenario from 'bundle-loader?lazy&name=editScenario!../pages/editScenario/index.js'
import TaskList from 'bundle-loader?lazy&name=tasks!../pages/taskList/index.js'
import TaskDetail from 'bundle-loader?lazy&name=taskDetail!../pages/taskDetail/index.js'
import MyTasks from 'bundle-loader?lazy&name=myTasks!../pages/myTasks/index.js'
import NotFound from 'bundle-loader?lazy&name=notFound!../pages/404/notFound.index.js'
import CreateTask from 'bundle-loader?lazy&name=createTask!../pages/createTask/index.js'
import EditTask from 'bundle-loader?lazy&name=editTask!../pages/editTask/index.js'
import Login from 'bundle-loader?lazy&name=login!../pages/login/index.js'
import Annotate from 'bundle-loader?lazy&name=annotate!../pages/annotate/index.js'
import AnnotateResult from 'bundle-loader?lazy&name=annotateResult!../pages/annotateResult/index.js'
import Conflict from 'bundle-loader?lazy&name=conflict!../pages/conflict/index.js'
import DutyTableIndex from 'bundle-loader?lazy&name=dutyTable!../pages/dutyTable/index.js'
import OnlineLearning from 'bundle-loader?lazy&name=onlineLearning!../pages/onlineLearning/index.js'
import EduAudioAnnotateStatistics from 'bundle-loader?lazy&name=eduAudioAnnotateStatistics!../pages/statistics/eduAudioAnnotateStatistics/index.js'
import EduAudioQualityTestStatistics from 'bundle-loader?lazy&name=eduAudioQualityTestStatistics!../pages/statistics/eduAudioQualityTestStatistics/index.js'
import DataSetManagement from 'bundle-loader?lazy&name=dataSetManagement!../pages/dataSetManagement/index.js'
import CreateDataSetManagement from 'bundle-loader?lazy&name=createDataSetManagement!../pages/createDataSetManagement/index.js'
import UserManage from 'bundle-loader?lazy&name=onlineLearning!../pages/userManage/index.js'
import Resources from 'bundle-loader?lazy&name=resource!../pages/resource/index.js'

const Loading = function () {
    return <div>Loading...</div>
}

const createComponent = (component) => (props) => (
    <Bundle load={component}>
        {
            (Component) => Component ? <Component {...props} /> : <Loading/>
        }
    </Bundle>
)

function hasLogin() {
    return !!window.localStorage.getItem("leyan_data_annotation_platform_auth")
}

const App = () => {
    return(<ConnectedRouter history={history}>
            <Switch>
                <Redirect from="/" to={hasLogin()?"/myTasks":"/login"} exact/>
                <Route path="/login" exact component={createComponent(Login)}/>
                <Route path="/onlineLearning/:id" exact component={createComponent(OnlineLearning)} />
                <Route path="/resource" component={createComponent(Resources)} />
                <Layout>
                    <Route path="/home" component={createComponent(Home)} />
                    <Route path="/scenarios" exact component={createComponent(Scenarios)} />
                    <Route path="/createScenario" component={createComponent(CreateScenario)} />
                    <Route path="/editScenario/:id" component={createComponent(EditScenario)} />
                    <Route path="/scenarios/:id" exact component={createComponent(TaskList)} />
                    <Route path="/scenario/:scenarioId/createTask" component={createComponent(CreateTask)} />
                    <Route path="/scenario/:scenarioId/:taskId/editTask" component={createComponent(EditTask)} />
                    <Route path="/taskDetail/:taskId" component={createComponent(TaskDetail)} />
                    <Route path="/conflict/:activityInstanceId" component={createComponent(Conflict)} />
                    <Route path="/myTasks" component={createComponent(MyTasks)} />
                    <Route path="/annotate/:subtaskId" component={createComponent(Annotate)} />
                    <Route path="/annotateResult/:subtaskId" component={createComponent(AnnotateResult)} />
                    <Route path="/dutyTable"  component={createComponent(DutyTableIndex)} />
                    <Route path="/eduAudioAnnotateStatistics"  component={createComponent(EduAudioAnnotateStatistics)} />
                    <Route path="/eduAudioQualityTestStatistics"  component={createComponent(EduAudioQualityTestStatistics)} />
                    <Route path="/dataSetManagement" component={createComponent(DataSetManagement)} />
                    <Route path="/createDataSetManagement" component={createComponent(CreateDataSetManagement)} />
                    <Route path="/userManage"  component={createComponent(UserManage)} />
                </Layout>
                <Route component={createComponent(NotFound)}/>
            </Switch>
        </ConnectedRouter>)
}

export default App
