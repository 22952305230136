/*eslint-disable*/
import axios from '../service'



let accountManageService = {}

const apis = [
    {
        name: 'getTableList',
        operationType: 'LIST_USER_MANAGEMENT',
        url: '/xbizapi/226/userandright'
    },
    {
        name: 'getAccountInfo',
        operationType: 'GET_USER_MANAGEMENT',
        url: '/xbizapi/226/userandright'
    },
    {
        name: 'changeAccountStatus',
        operationType: 'DISABLE_USER',
        url: '/xbizapi/226/userandright'
    },
    {
        name: 'submitAccountInfo',
        operationType: 'SAVE_USER_MANAGEMENT',
        url: '/xbizapi/226/userandright'
    },
    {
        name: 'getFieldList',
        operationType: 'LIST_FILED',
        url: '/xbizapi/226/scenario'
    },
    {
        name: 'getSceneList',
        operationType: 'LIST_SCENE_BY_FIELD',
        url: '/xbizapi/226/scenario'
    },


]

apis.map((api) => {
    accountManageService[api.name] = (data) => {
        return axios(api.url, {
            data: Object.assign({}, {
                operationType: api.operationType,
            }, data),
        })
    }
})

export default accountManageService
