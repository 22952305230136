import React, {Component} from 'react'
import propTypes from 'prop-types'
import {Icon} from 'antd'
import './index.css'

class PageLoading extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: true
        }
    }

    static getDerivedStateFromProps(nextProps, nextState) {
        if(nextProps.show!=nextState.show) {
            return {
                show: nextProps.show,
            }
        } else {
            return null
        }
    }

    render() {
        return(<div className={this.state.show?"pageMask show":"pageMask hide"}>
            <div className="loadingContent">
                <Icon type="loading" className="loadingIcon" />
                <p>加载中。。。</p>
            </div>
        </div>)
    }
}

PageLoading.propTypes = {
    show: propTypes.bool
}

export default PageLoading