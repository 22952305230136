import scheduleListService from '../../service/services/scheduleListService'
import {handleResultCode} from "../../service/service"
import {message} from 'antd'

const START_LOADING_SCHEDULE_LIST = 'START_LOADING_SCHEDULE_LIST',
    FINISH_LOADING_SCHEDULE_LIST = 'FINISH_LOADING_SCHEDULE_LIST',
    START_DELETING_SCHEDULE = 'START_DELETING_SCHEDULE',
    FINISH_DELETING_SCHEDULE = 'FINISH_DELETING_SCHEDULE',
    START_SUBMIT_SCHEDULE = 'START_SUBMIT_SCHEDULE',
    FINISH_SUBMIT_SCHEDULE = 'FINISH_SUBMIT_SCHEDULE',
    START_UPDATE_SCHEDULE = 'START_UPDATE_SCHEDULE',
    FINISH_UPDATE_SCHEDULE='FINISH_UPDATE_SCHEDULE'

const initState = {
    scheduleListIsFetching: false,
    pageIndex: 1,
    pageSize: 10,
    totalPageCount: 1,
    totalItemCount: 1,
    scheduleSettingList: [],
    submitSuccess: false,
}
/**
 * 获取班次列表
 **/

export function startGettingScheduleList() {
    return {
        type: START_LOADING_SCHEDULE_LIST,
    }
}

export function finishGettingScheduleList(res) {
    return {
        type: FINISH_LOADING_SCHEDULE_LIST,
        pageIndex: res.pageIndex,
        pageSize: res.pageSize,
        totalPageCount: res.totalPageCount,
        totalItemCount: res.totalItemCount,
        scheduleSettingList: res.classesList
    }
}

export function getScheduleList(params) {
    return dispatch => {
        dispatch(startGettingScheduleList())
        scheduleListService.query(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    let {pageIndex, pageSize, classesList, totalItemCount, totalPageCount} = res.data
                    dispatch(finishGettingScheduleList({pageIndex, pageSize, classesList, totalItemCount, totalPageCount}))
                }
            })
            .catch(()=> {
            })
    }
}

export function scheduleListReducer(state = initState, action) {
    switch (action.type) {
        case START_LOADING_SCHEDULE_LIST:
            return {
                ...state,
                scheduleListIsFetching: true
            }
        case FINISH_LOADING_SCHEDULE_LIST:
            return {
                ...state,
                scheduleListIsFetching: false,
                pageIndex: action.pageIndex,
                pageSize: action.pageSize,
                scheduleSettingList: action.scheduleSettingList,
                totalItemCount: action.totalItemCount,
                totalPageCount: action.totalPageCount
            }
        default:
            return state
    }
}

/**
 * 班次的删除
 **/

export function deleteSchedule(params) {
    return dispatch => {
        dispatch(startDeleteSchedule())
        scheduleListService.delete(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                dispatch(finishDeleteSchedule())
                if(res.success) {
                    message.success('删除成功')
                    dispatch(getScheduleList())
                }
            })
            .catch(()=> {
            })
    }
}

function startDeleteSchedule() {
    return {
        type: START_DELETING_SCHEDULE
    }
}

function finishDeleteSchedule() {
    return {
        type: FINISH_DELETING_SCHEDULE
    }
}


/**
 * 班次的提交
 **/

export function submitSchedule(params, successCB) {
    return dispatch => {
        dispatch(startSubmitSchedule())
        scheduleListService.add(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                dispatch(finishSubmitSchedule())
                if(res.success) {
                    message.success('添加班次成功')
                    successCB()
                    dispatch(getScheduleList())
                }
            })
            .catch(()=> {
            })
    }
}

function startSubmitSchedule() {
    return {
        type: START_SUBMIT_SCHEDULE
    }
}

function finishSubmitSchedule() {
    return {
        type: FINISH_SUBMIT_SCHEDULE
    }
}


/**
 * 班次的修改
 **/

export function updateSchedule(params, successCB) {
    return dispatch => {
        dispatch(startUpdateSchedule())
        scheduleListService.update(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                dispatch(finishUpdateSchedule())
                if(res.success) {
                    message.success('更新班次成功')
                    successCB()
                    dispatch(getScheduleList())
                }
            })
            .catch(()=> {
            })
    }
}

function startUpdateSchedule() {
    return {
        type: START_UPDATE_SCHEDULE
    }
}

function finishUpdateSchedule() {
    return {
        type: FINISH_UPDATE_SCHEDULE
    }
}