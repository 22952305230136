/**
 * 根据parentID将一维数组转化为对应的树结构数组
 * hasOldArray是否返回原来的数组
 */
function getTranslateArray(array, hasOldArray) {
    let oldArray = [...array]
    array.sort((a, b) => a.parentId - b.parentId)
    const midObj = {}
    // 从后向前遍历
    for(let i = array.length - 1; i >= 0; i--) {
        const nowPid = array[i].parentId
        const nowId = array[i].id
        // 建立当前节点的父节点的chlidFuns 数组
        if(midObj[nowPid]) {
            midObj[nowPid].unshift(array[i])
        } else {
            midObj[nowPid] = []
            midObj[nowPid].unshift(array[i])
        }
        // 将chlidFuns 放入合适的位置
        if(midObj[nowId]) {
            array[i].chlidFuns = midObj[nowId]
            delete midObj[nowId]
        }
    }
    if(hasOldArray) {
        return {
            newArray: midObj[0],
            oldArray
        }
    }
    return  midObj[0]
}

/**
 * 判断对象是否为Null或Undefined
 */
function isNullOrUndefined(obj) {
    return typeof obj === "undefined" || obj === null || obj === ''
}

const AUDIO_MARK_TYPES = [
    {
        type: 'FULLTEXT',
        name: '全文标注',
        desc: '默认的标注'
    },{
        type: 'ACCURATE',
        name: '精准标注',
        desc: 'sprint57新增的标注'
    },
]

export {getTranslateArray, isNullOrUndefined, AUDIO_MARK_TYPES}
