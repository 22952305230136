import datasetimport from '../../service/services/dataSetManagement'
import {handleResultCode} from "../../service/service"
import {Modal} from 'antd'
import {push} from "react-router-redux"

const START_LOADING_DATASET_LIST = 'START_LOADING_DATASET_LIST',
    FINISH_LOADING_DATASET_LIST = 'FINISH_LOADING_DATASET_LIST',
    START_ADDING_DATASET_LIST = 'START_ADDING_DATASET_LIST',
    FINISH_ADDING_DATASET_LIST = 'FINISH_ADDING_DATASET_LIST'

const initState = {
    dataSetManagementIsFetching: false,
    importInfos: {
        pageIndex: 1,
        pageSize: 10,
        totalItemCount: 1,
        totalPageCount: 1,
        importInfos: []
    }
}

export function startGettingDataSetManagements() {
    return {
        type: START_LOADING_DATASET_LIST
    }
}

export function finishGettingDataSetManagements(res) {
    return {
        type: FINISH_LOADING_DATASET_LIST,
        importInfos: res
    }
}

export function getDataSetManagements(params) {
    return dispatch => {
        dispatch(startGettingDataSetManagements())
        datasetimport.importQueryByPage(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    let {pageIndex, pageSize, importInfos, totalItemCount, totalPageCount} = res.data
                    dispatch(finishGettingDataSetManagements({pageIndex, pageSize, importInfos, totalItemCount, totalPageCount}))
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

function startAddingDataSetManagements() {
    return {
        type: START_ADDING_DATASET_LIST
    }
}

function finishAddingDataSetManagements() {
    return {
        type: FINISH_ADDING_DATASET_LIST
    }
}

export function createDataSetManagement(params) {
    return dispatch => {
        dispatch(startAddingDataSetManagements())
        datasetimport.DataSetImport(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    dispatch(finishAddingDataSetManagements())
                    Modal.success({
                        title: '新建数据集成功！',
                        onOk() {
                            dispatch(push('/dataSetManagement'))
                        }
                    })
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

export function dataSetManagement(state = initState, action) {
    switch (action.type) {
        case START_LOADING_DATASET_LIST:
            return {
                ...state,
                dataSetManagementIsFetching: true
            }
        case FINISH_LOADING_DATASET_LIST:
            return {
                ...state,
                dataSetManagementIsFetching: false,
                importInfos: action.importInfos
            }
        case START_ADDING_DATASET_LIST:
            return {
                ...state
            }
        case FINISH_ADDING_DATASET_LIST:
            return {
                ...state
            }
        default:
            return state
    }
}