import { handleResultCode } from '../../service/service'
import accountManageService from '../../service/services/accountManage'
import roleManageService from "../../service/services/roleManage"
const START_GET_TABLE_LIST = 'START_GET_TABLE_LIST'
const DID_GET_TABLE_LIST = 'DID_GET_TABLE_LIST'
const FAIL_GET_TABLE_LIST = 'FAIL_GET_TABLE_LIST'


const START_GET_ACCOUNT_INFO = 'START_GET_ACCOUNT_INFO'
const DID_GET_ACCOUNT_INFO = 'DID_GET_ACCOUNT_INFO'
const FAIL_GET_ACCOUNT_INFO = 'FAIL_GET_ACCOUNT_INFO'

const START_SUBMIT_ACCOUNT_INFO = 'START_SUBMIT_ACCOUNT_INFO'
const DID_SUBMIT_ACCOUNT_INFO = 'DID_SUBMIT_ACCOUNT_INFO'
const FAIL_SUBMIT_ACCOUNT_INFO = 'FAIL_SUBMIT_ACCOUNT_INFO'

const START_CHANGE_ACCOUNT_STATUS = 'START_CHANGE_ACCOUNT_STATUS'
const DID_CHANGE_ACCOUNT_STATUS = 'DID_CHANGE_ACCOUNT_STATUS'
const FAIL_CHANGE_ACCOUNT_STATUS = 'FAIL_CHANGE_ACCOUNT_STATUS'

const START_GET_FIELD_LIST = 'START_GET_FIELD_LIST'
const DID_GET_FIELD_LIST = 'DID_GET_FIELD_LIST'
const FAIL_GET_FIELD_LIST = 'FAIL_GET_FIELD_LIST'

const START_GET_SCENE_LIST = 'START_GET_SCENE_LIST'
const DID_GET_SCENE_LIST = 'DID_GET_SCENE_LIST'
const FAIL_GET_SCENE_LIST = 'FAIL_GET_SCENE_LIST'

const START_GET_QUERY_ROLE_LIST = 'START_GET_QUERY_ROLE_LIST'
const DID_GET_QUERY_ROLE_LIST = 'DID_GET_QUERY_ROLE_LIST'
const FAIL_GET_QUERY_ROLE_LIST= 'FAIL_GET_QUERY_ROLE_LIST'

const INIT_ACCOUNT_INFO = 'INIT_ACCOUNT_INFO'

const initState = {
    isFetchTableList: false,
    isFetchSubmit: false,
    isFetchModalContent: false,
    totalItemCount: 0,
    rows: [],
    accountInfo: {},
    fieldList: [],
    sceneList: [],
    roleNameList: [],
    queryRoleList: []
}

// 获取table 数据
export function getTableList(params, successCb, failCb) {
    return (dispatch) => {
        dispatch(startGetTableList())
        accountManageService.getTableList(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    let {totalItemCount, userManagementDTOS} = res.data
                    dispatch(didGetTableList(totalItemCount, userManagementDTOS))
                    successCb()
                } else {
                    dispatch(failGetTableList())
                    failCb()
                }
            })
            .catch(() => {
                dispatch(failGetTableList())
                failCb()
            })
    }
}

function startGetTableList() {
    return {
        type: START_GET_TABLE_LIST
    }
}

function didGetTableList(totalItemCount, rows) {
    return {
        type: DID_GET_TABLE_LIST,
        totalItemCount,
        rows,
    }
}

function failGetTableList() {
    return {
        type: FAIL_GET_TABLE_LIST
    }
}


// getAccountInfo 查看账户信息
export function getAccountInfo(params) {
    return (dispatch) => {
        dispatch(startGetAccountInfo())
        accountManageService.getAccountInfo(params)
            .then((response) => {
                dispatch(failGetAccountInfo())
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    const {
                        id,
                        username,
                        roleId,
                        scopeType,
                        scopeId,
                        nickname,
                        userType,
                        email,
                        userRoleId,
                        fieldInfo,
                        sceneInfo
                    } = res.data.userManagementDTOS.length && res.data.userManagementDTOS[0]
                    let accountInfo = {}
                    accountInfo.id = id
                    accountInfo.username = username
                    accountInfo.roleId = roleId
                    accountInfo.scopeType = scopeType
                    accountInfo.scopeId = scopeId
                    accountInfo.nickname = nickname
                    accountInfo.userType = userType
                    accountInfo.email = email
                    accountInfo.userRoleId = userRoleId
                    accountInfo.fieldInfo = fieldInfo
                    accountInfo.sceneInfo = sceneInfo
                    dispatch(didGetAccountInfo(accountInfo))
                }
            })
            .catch(() => {
                dispatch(failGetAccountInfo())
                return
            })
    }
}

function startGetAccountInfo() {
    return {
        type: START_GET_ACCOUNT_INFO
    }
}

function didGetAccountInfo(accountInfo) {
    return {
        type: DID_GET_ACCOUNT_INFO,
        accountInfo
    }
}

function failGetAccountInfo() {
    return {
        type: FAIL_GET_ACCOUNT_INFO
    }
}


// changeAccountStatus 停用/ 启用账户
export function changeAccountStatus(params, cb) {
    return (dispatch) => {
        dispatch(startChangeAccountStatus())
        accountManageService.changeAccountStatus(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(didChangeAccountStatus())
                    if (cb) cb()
                }
            })
            .catch(() => {
                dispatch(failChangeAccountStatus())
                if (cb) cb()
            })
    }
}

function startChangeAccountStatus() {
    return {
        type: START_CHANGE_ACCOUNT_STATUS
    }
}

function didChangeAccountStatus() {
    return {
        type: DID_CHANGE_ACCOUNT_STATUS
    }
}

function failChangeAccountStatus() {
    return {
        type: FAIL_CHANGE_ACCOUNT_STATUS
    }
}


// submitAccountInfo 添加/ 编辑提交
export function submitAccountInfo(params, successCb, failCb) {
    return (dispatch) => {
        dispatch(startSubmitAccountInfo())
        accountManageService.submitAccountInfo(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(didSubmitAccountInfo())
                    successCb()
                } else {
                    dispatch(failSubmitAccountInfo())
                    failCb()
                }
            })
            .catch(() => {
                dispatch(failSubmitAccountInfo())
                failCb()
            })
    }
}

function startSubmitAccountInfo() {
    return {
        type: START_SUBMIT_ACCOUNT_INFO
    }
}

function didSubmitAccountInfo() {
    return {
        type: DID_SUBMIT_ACCOUNT_INFO
    }
}

function failSubmitAccountInfo() {
    return {
        type: FAIL_SUBMIT_ACCOUNT_INFO
    }
}


// 获取领域列表
export function getFieldList(params, successCb, failCb) {
    return (dispatch) => {
        dispatch(startGetFieldList())
        accountManageService.getFieldList(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    let {fieldList} = res.data
                    dispatch(didGetFieldList(fieldList))
                    successCb()
                } else {
                    dispatch(failGetFieldList())
                    failCb()
                }
            })
            .catch(() => {
                dispatch(failGetFieldList())
                failCb()
            })
    }
}

function startGetFieldList() {
    return {
        type: START_GET_FIELD_LIST
    }
}

function didGetFieldList(fieldList) {
    return {
        type: DID_GET_FIELD_LIST,
        fieldList
    }
}

function failGetFieldList() {
    return {
        type: FAIL_GET_FIELD_LIST
    }
}

// 获取场景列表
export function getSceneList(params) {
    return (dispatch) => {
        dispatch(startGetScenedList())
        accountManageService.getSceneList(params)
            .then((response) => {
                dispatch(failGetSceneList())
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    let {scenarioList} = res.data
                    dispatch(didGetSceneList(scenarioList))
                }
            })
            .catch(() => {
                dispatch(failGetSceneList())
                return
            })
    }
}

function startGetScenedList() {
    return {
        type: START_GET_SCENE_LIST
    }
}

function didGetSceneList(sceneList) {
    return {
        type: DID_GET_SCENE_LIST,
        sceneList
    }
}

function failGetSceneList() {
    return {
        type: FAIL_GET_SCENE_LIST
    }
}


// 模糊查询角色列表
export function getQueryRoleList(params, successCb, failCb) {
    return (dispatch) => {
        dispatch(startGetQueryRoleList())
        roleManageService.getQueryRoleList(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    let {roleDTOList} = res.data
                    let queryRoleList = (roleDTOList.length && roleDTOList) || []
                    dispatch(didGetQueryRoleList(queryRoleList))
                } else {
                    dispatch(failGetQueryRoleLoList())
                    failCb()
                }
            })
            .catch(() => {
                dispatch(failGetQueryRoleLoList())
                failCb()
            })
    }
}

function startGetQueryRoleList() {
    return {
        type: START_GET_QUERY_ROLE_LIST
    }
}

function didGetQueryRoleList(queryRoleList) {
    return {
        type: DID_GET_QUERY_ROLE_LIST,
        queryRoleList
    }
}

function failGetQueryRoleLoList() {
    return {
        type: FAIL_GET_QUERY_ROLE_LIST
    }
}


export function accountManage(state = initState, action) {
    switch (action.type) {
        case START_GET_TABLE_LIST:
            return {
                ...state,
                isFetchTableList: true
            }
        case DID_GET_TABLE_LIST:
            return {
                ...state,
                isFetchTableList: false,
                rows: action.rows,
                totalItemCount: action.totalItemCount,
            }
        case FAIL_GET_TABLE_LIST:
            return {
                ...state,
                isFetchTableList: false
            }
        case START_GET_FIELD_LIST:
            return {
                ...state,
            }
        case DID_GET_FIELD_LIST:
            return {
                ...state,
                fieldList: action.fieldList
            }
        case FAIL_GET_FIELD_LIST:
            return {
                ...state,
            }
        case START_GET_SCENE_LIST:
            return {
                ...state,
            }
        case DID_GET_SCENE_LIST:
            return {
                ...state,
                sceneList: action.sceneList
            }
        case FAIL_GET_SCENE_LIST:
            return {
                ...state,
                sceneList: []
            }

        case START_GET_ACCOUNT_INFO:
            return {
                ...state,
                isFetchModalContent: true,
            }
        case DID_GET_ACCOUNT_INFO:
            return {
                ...state,
                isFetchModalContent: false,
                accountInfo: action.accountInfo
            }
        case FAIL_GET_ACCOUNT_INFO:
            return {
                ...state,
                isFetchModalContent: false
            }

        case START_CHANGE_ACCOUNT_STATUS:
            return {
                ...state,
                isFetchTableList: true
            }
        case DID_CHANGE_ACCOUNT_STATUS:
            return {
                ...state,
                isFetchTableList: false,
            }
        case FAIL_CHANGE_ACCOUNT_STATUS:
            return {
                ...state,
                isFetchTableList: false
            }
        case INIT_ACCOUNT_INFO:
            return {
                ...state,
                accountInfo: {}
            }

        case START_SUBMIT_ACCOUNT_INFO:
            return {
                ...state,
                isFetchSubmit: true,
            }
        case DID_SUBMIT_ACCOUNT_INFO:
            return {
                ...state,
                isFetchSubmit: false,
            }
        case FAIL_SUBMIT_ACCOUNT_INFO:
            return {
                ...state,
                isFetchSubmit: false
            }
        case START_GET_QUERY_ROLE_LIST:
            return {
                ...state,
                isFetchQueryRoleList: true
            }
        case DID_GET_QUERY_ROLE_LIST:
            return {
                ...state,
                isFetchQueryRoleList: false,
                queryRoleList: action.queryRoleList,
            }
        case FAIL_GET_QUERY_ROLE_LIST:
            return {
                ...state,
                isFetchQueryRoleList: false,
                queryRoleList: []
            }
        default:
            return state
    }
}
