import axios from '../service'

const url = '/xbizapi/226/dataset'

var dataset = {}

const apis = [{
    name: "query",
    operationType: "QUERY_DATASET_LIST"
},{
    name: "count",
    operationType: "COUNT_DATASET"
}]

apis.map((api) => {
    dataset[api.name] = function (data) {
        return axios(url, {
            data: Object.assign({}, {operationType: api.operationType}, data)
        })
    }
})

export default dataset