import myTask from '../../service/services/myTask'
import {handleResultCode} from "../../service/service"

const START_LOADING_MYTASK_LIST = 'START_LOADING_MYTASK_LIST',
    FINISH_LOADING_MYTASK_LIST = 'FINISH_LOADING_MYTASK_LIST'

const initState = {
    myTaskListIsFetching: false,
    pageIndex: 1,
    pageSize: 10,
    totalPageCount: 1,
    totalItemCount: 1,
    myTaskList: []
}

export function startGettingMyTaskList() {
    return {
        type: START_LOADING_MYTASK_LIST
    }
}

export function finishGettingMyTaskList(res) {
    return {
        type: FINISH_LOADING_MYTASK_LIST,
        pageIndex: res.pageIndex,
        pageSize: res.pageSize,
        totalPageCount: res.totalPageCount,
        totalItemCount: res.totalItemCount,
        myTaskList: res.systemTaskDTOList
    }
}

export function getMyTasks(params) {
    return dispatch => {
        dispatch(startGettingMyTaskList())
        myTask.query(params)
            .then((response)=>{
                return handleResultCode(response)
            })
            .then((res)=>{
                if(res.success) {
                    let {pageIndex, pageSize, systemTaskDTOList, totalItemCount, totalPageCount} = res.data
                    dispatch(finishGettingMyTaskList({pageIndex, pageSize, systemTaskDTOList, totalItemCount, totalPageCount}))
                }
            })
            .catch((err)=> {
                console.log("---Oops, error!---")
                console.log(err)
            })
    }
}

export function myTaskList(state = initState, action) {
    switch (action.type) {
        case START_LOADING_MYTASK_LIST:
            return {
                ...state,
                myTaskListIsFetching: true
            }
        case FINISH_LOADING_MYTASK_LIST:
            return {
                ...state,
                myTaskListIsFetching: false,
                pageIndex: action.pageIndex,
                pageSize: action.pageSize,
                myTaskList: action.myTaskList,
                totalItemCount: action.totalItemCount,
                totalPageCount: action.totalPageCount
            }
        default:
            return state
    }
}