import mainTask from '../../service/services/mainTask'
import merge from '../../service/services/merge'
import {handleResultCode} from "../../service/service"
import {Modal} from 'antd'
import {push} from "react-router-redux"

const START_LOADING_TASK_DETAIL = 'START_LOADING_TASK_DETAIL',
    FINISH_LOADING_TASK_DETAIL = 'FINISH_LOADING_TASK_DETAIL',
    START_SPLITING = 'START_SPLITING',
    FINISH_SPLITING = 'FINISH_SPLITING',
    START_ASSIGN = 'START_ASSIGN',
    FINISH_ASSIGN = 'FINISH_ASSIGN',
    START_MERGING = 'START_MERGING',
    FINISH_MERGING = 'FINISH_MERGING',
    HANDLE_ERROR = 'HANDLE_ERROR',
    START_CHECK_ASSIGN = 'START_CHECK_ASSIGN',
    FINISH_CHECK_ASSIGN = 'FINISH_CHECK_ASSIGN'

const initState = {
    taskIsOperating: false,
    taskDetailIsFetching: false,
    taskDetail: null,
    dividedList: [],
    assignStrategy: null,
    reviewInfo: [],
    notReviewInfo: {}
}

function startGettingTaskDetail() {
    return {
        type: START_LOADING_TASK_DETAIL
    }
}

function finishGettingTaskDetail(res) {

    return {
        type: FINISH_LOADING_TASK_DETAIL,
        taskDetail: res.mainTaskDTO,
        dividedList: res.dividedDTOList,
        assignStrategy: res.assignStrategyDTO,
        reviewInfo: res.reviewInfo,
        notReviewInfo: res.notReviewInfo
    }
}

function handleError() {
    return {
        type: HANDLE_ERROR
    }
}

export function getTaskDetail(params) {
    return dispatch => {
        dispatch(startGettingTaskDetail())
        mainTask.queryDetail(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if(res.success) {
                    let {mainTaskDTO, assignStrategyDTO, dividedDTOList, reviewInfo, notReviewInfo} = res.data
                    dispatch(finishGettingTaskDetail({mainTaskDTO, assignStrategyDTO, dividedDTOList, reviewInfo, notReviewInfo}))
                } else {
                    dispatch(handleError())
                }
            })
    }
}

function startSpliting() {
    return {
        type: START_SPLITING
    }
}

function finishSpliting() {
    return {
        type: FINISH_SPLITING
    }
}

export function splitDataset(params, cb) {
    return dispatch => {
        dispatch(startSpliting())
        mainTask.split(params)
            .then((response) => {
                return handleResultCode(response, cb)
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishSpliting())
                    Modal.success({
                        title: '分割成功！',
                        ZIndex: 10001,
                        onOk() {
                            location.reload()
                        }
                    })
                } else {
                    cb()
                    dispatch(handleError())
                }
            })
            .catch(() => {
                cb()
            })
    }
}

function startAssign() {
    return {
        type: START_ASSIGN
    }
}

function finishAssign() {
    return {
        type: FINISH_ASSIGN
    }
}

export function assignTask(params, cb) {
    return dispatch => {
        dispatch(startAssign())
        mainTask.assign(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishAssign())
                    Modal.success({
                        title: '分配成功！',
                        ZIndex: 10001,
                        onOk() {
                            location.reload()
                        }
                    })
                } else {
                    cb()
                    dispatch(handleError())
                }
            })
            .catch(() => {
                cb()
            })
    }
}

function startMerging() {
    return {
        type: START_MERGING
    }
}

function finishMerging() {
    return {
        type: FINISH_MERGING,
    }
}

export function mergeInActivity(params, cb) {
    return dispatch => {
        dispatch(startMerging())
        merge.autoInActivity(params)
            .then((response) => {
                return handleResultCode(response, cb)
            })
            .then((res) => {
                if(res.success) {
                    dispatch(finishMerging())
                    Modal.success({
                        title: '已开始合并！',
                        onOk: () => {
                            // 表示没有权限, 需跳转到场景任务
                            if (res.data.disabled) {
                                dispatch(push('/scenarios'))
                            } else {
                                window.location.reload()
                            }
                        }
                    })
                } else {
                    cb()
                    dispatch(handleError())
                }
            })
            .catch(() => {
                cb()
            })
    }
}

function startCheckAssign() {
    return {
        type: START_CHECK_ASSIGN
    }
}

function finishCheckAssign() {
    return {
        type: FINISH_CHECK_ASSIGN
    }
}

export function checkAssignTask(params, cb) {
    return dispatch => {
        dispatch(startCheckAssign())
        mainTask.checkAssign(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    cb(res.data)
                    dispatch(finishCheckAssign())
                }
            })
            .catch(() => {
                dispatch(finishCheckAssign())
            })
    }
}
export function taskDetail(state = initState, action) {
    switch (action.type) {
        case START_MERGING:
        case START_SPLITING:
        case START_ASSIGN:
            return {
                ...state,
                taskIsOperating: true
            }
        case START_LOADING_TASK_DETAIL:
            return {
                ...state,
                taskDetailIsFetching: true
            }
        case FINISH_LOADING_TASK_DETAIL:
            return {
                ...state,
                taskDetailIsFetching: false,
                taskDetail: action.taskDetail,
                dividedList: action.dividedList,
                assignStrategy: action.assignStrategy,
                reviewInfo: action.reviewInfo,
                notReviewInfo: action.notReviewInfo
            }
        case FINISH_ASSIGN:
        case FINISH_SPLITING:
        case FINISH_MERGING:
            return {
                ...state,
                taskIsOperating: false
            }
        case HANDLE_ERROR:
            return {
                ...state,
                taskIsOperating: false,
                taskDetailIsFetching: false
            }
        case START_CHECK_ASSIGN:
        case FINISH_CHECK_ASSIGN:
        default:
            return state
    }
}
